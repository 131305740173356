/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { useLocalization, useAuth } from "../../context";
import { NavLink, useHistory } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import {
  BiLinkAlt,
  BiShoppingBag,
  BiX,
  BiUser,
  BiLogInCircle,
} from "react-icons/bi";
import Config from "../../appConfig";
import queryString from "query-string";

export default function NavBar() {
  const { localization, direction, setLang } = useLocalization();
  const { authToken, userData } = useAuth();
  const [StatusNav, SetStatusNav] = useState(false);
  const [dropdow, setDropDow] = useState({
    bg: false,
  });
  const [searchType, setSearchType] = useState("stores");
  const [serachValue, setSearchValue] = useState("");
  const history = useHistory();
  const queryStringParams = queryString.parse(window.location.search);

  const { innerWidth: width } = window;


  const styleHide = {
    menu: {
      postion: "absolute",
      right: "-300px",
    },
    over: {
      display: "none",
    },
  };

  const styleShow = {
    menu: {
      postion: "absolute",
      width: width < 360 ? "100%" : '300px',
      right: "0px",
    },
    over: {
      display: "block",
    },
  };

  const [styleSideBar, setStyleSideBar] = useState(styleHide);

  useEffect(() => {
    if (StatusNav) {
      setStyleSideBar(styleShow);
    } else {
      setStyleSideBar(styleHide);
    }
  }, [StatusNav]);

  useEffect(() => {
    if (queryStringParams) {
      if (queryStringParams.type) {
        setSearchType(queryStringParams.type);
      }
      if (queryStringParams.q) {
        setSearchValue(queryStringParams.q);
      }
    }
  }, []);

  const _dropdown = (key) =>
    setDropDow({
      ...dropdow,
      [key]: !dropdow[key],
      bg: !dropdow[key],
    });

  const _onSearch = (event) => {
    event.preventDefault();

    const elements = event.target.elements;
    if (elements["q"] && elements["type"]) {
      const value = elements["q"].value;
      if (value.trim() === "") {
        return false;
      }
      history.push(
        `/${elements["type"].value}/?q=${value}&type=${elements["type"].value}`
      );
    }
  };

  const SearchInput = (
    <form onSubmit={_onSearch} className="w-100">
      <div className="input-group">
        <div className="input-group-prepend">
          <select
            name="type"
            className="select-search"
            value={searchType}
            onChange={(event) => setSearchType(event.target.value)}>
            <option value={"stores"}>{localization.stores}</option>
            <option value={"coupons"}>{localization.coupons}</option>
          </select>
          <div className="separator"></div>
        </div>
        <input
          placeholder={`${localization.searchInput}...`}
          required
          defaultValue={serachValue}
          type="text"
          name="q"
          className="form-control"
        />
        <div className="input-group-append">
          <button className="btn btn-link btn-bg">
            <img src={`${Config.assetsUrl}/search.w.svg`} alt="" />
          </button>
        </div>
      </div>
    </form>
  );

  const _setLang = (lang) => {
    //console.log(history);
    //console.log(localization._locale);
    const url = history.location.pathname.replace(
      `/${localization._locale}`,
      ""
    );
    //console.log(url);
    if (url !== "/") {
      history.replace(`${url}/${lang}`);
    }
    setLang(lang);
  };

  return (
    <Fragment>
      <div className={`navbar_p ${direction}`}>
        <Fragment>
          <div
            id="over"
            style={styleSideBar.over}
            onClick={() => SetStatusNav(false)}
            className="menuback"></div>
          <div id="menu_side" className="menu_side" style={styleSideBar.menu}>
            <div className="side_logo_menu">
              <div className="close_navside">
                <button onClick={() => SetStatusNav(false)}>
                  {" "}
                  <BiX color="#fff" size="1.8em" />{" "}
                </button>
              </div>
              <div className="side_logo_menu_logo">
                <NavLink to="/">
                  <img src={`${Config.assetsUrl}/logo.png`} alt="" />
                </NavLink>
              </div>
            </div>

            <div className="side_menu_menu">
              <ul className="list-group list-group-flush w-100 pr-0 ">
                <li className="list-group-item w-100">
                  <NavLink activeClassName="active" to="/profile">
                    <button type="button" className="btn w-100  profile_btn ">
                      <div className="icon_profile_item">
                        <BiShoppingBag color="#fffff" size="1.5em" />
                      </div>

                      <div className="title_profile_item">
                        {localization.stores}
                      </div>
                    </button>
                  </NavLink>
                </li>
                {authToken ? (
                  <React.Fragment>
                    <li className="list-group-item w-100">
                      <NavLink to={"/profile"}>
                        <button
                          type="button"
                          className="btn w-100  profile_btn ">
                          <div className="icon_profile_item">
                            <BiUser color="#D0D7E0" size="1.5em" />
                          </div>
                          <div className="title_profile_item">
                            {userData.name}
                          </div>
                        </button>
                      </NavLink>
                    </li>
                    <li className="list-group-item w-100">
                      <NavLink to={"/logout"}>
                        <button
                          type="button"
                          className="btn w-100  profile_btn ">
                          <div className="icon_profile_item">
                            <BiLinkAlt color="#D0D7E0" size="1.5em" />
                          </div>
                          <div className="title_profile_item">
                            {localization.logout}
                          </div>
                        </button>
                      </NavLink>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="list-group-item w-100">
                      <NavLink to={"/signup"}>
                        <button
                          type="button"
                          className="btn w-100  profile_btn">
                          <div className="icon_profile_item">
                            <BiLogInCircle color="#D0D7E0" size="1.5em" />
                          </div>
                          <div className="title_profile_item">
                            {localization.register}
                          </div>
                        </button>
                      </NavLink>
                    </li>
                    <li className="list-group-item w-100">
                      <NavLink to={"/login"}>
                        <button
                          type="button"
                          className="btn w-100  profile_btn">
                          <div className="icon_profile_item">
                            <BiLogInCircle color="#D0D7E0" size="1.5em" />
                          </div>
                          <div className="title_profile_item">
                            {localization.loginTitle}
                          </div>
                        </button>
                      </NavLink>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </Fragment>

        <div className="navbar_p_top w-100">
          <div className="container w-100 d-flex  justify-content-between align-items-center">
            <div id="logo_menu" className="logo_menu">
              <NavLink to="/">
                {" "}
                <img src={`${Config.assetsUrl}/logo.png`} alt="" />
              </NavLink>
            </div>

            <div className="search_menu searchmobilesection">
              <div className="search_main w-100">{SearchInput}</div>
            </div>

            <div className="login_menu">
              <div className="main_login_button">
                {authToken ? (
                  <>
                    <NavLink to={"/logout"}>
                      <button className="signup_btn">
                        {localization.logout}
                      </button>
                    </NavLink>
                    <div className="sep"></div>
                    <NavLink to={"/profile"}>
                      <button className="signup_btn">{userData.name}</button>
                    </NavLink>
                  </>
                ) : (
                  <>
                    <NavLink to={"/signup"}>
                      <button className="signup_btn">
                        {localization.register}
                      </button>
                    </NavLink>
                    <div className="sep"></div>
                    <NavLink to={"/login"}>
                      <button className="login_btn">
                        {localization.connection}
                      </button>
                    </NavLink>
                  </>
                )}
              </div>
            </div>

            <div className="menu_hm">
              <button
                onClick={() => SetStatusNav(!StatusNav)}
                className="menu_hm_btn">
                <BiMenu size="1.8em" />
              </button>
            </div>
          </div>
        </div>

        <div className={`menu_bottom ${direction}`}>
          <div className="container p-0">
            <ul>
              <li>
                <div className={`dropdown ${direction}`}>
                  <button
                    className="dropdown-toggle menu_bottom_drop"
                    type="button"
                    onClick={() => _dropdown("stores")}>
                    {localization.stores}
                  </button>
                  <div
                    className={`dropdown-menu ${dropdow.stores ? "show" : ""}`}>
                    <NavLink to="/stores" className="dropdown-item">
                      {localization.stores}
                    </NavLink>
                    <NavLink to="/coupons" className="dropdown-item">
                      {localization.coupons}
                    </NavLink>
                  </div>
                </div>
              </li>

              <li>
                <div className={`dropdown ${direction}`}>
                  <button
                    className="dropdown-toggle menu_bottom_drop"
                    type="button"
                    onClick={() => _dropdown("pages")}>
                    {localization.help}
                  </button>
                  <div
                    className={`dropdown-menu ${dropdow.pages ? "show" : ""}`}>
                    <NavLink to="/contact" className="dropdown-item">
                      {localization.contactUs}
                    </NavLink>
                    <NavLink to="/about" className="dropdown-item">
                      {localization.about}
                    </NavLink>
                    <NavLink
                      to={`/page/terms-of-services/${localization._locale}`}
                      className="dropdown-item">
                      {localization.pages["terms-of-services"]}
                    </NavLink>
                    <NavLink
                      to={`/page/privacy-policy/${localization._locale}`}
                      className="dropdown-item">
                      {localization.pages["privacy-policy"]}
                    </NavLink>
                    <NavLink
                      to={`/page/how-to-buy-with-a-cashback/${localization._locale}`}
                      className="dropdown-item">
                      {localization.pages["how-to-buy-with-a-cashback"]}
                    </NavLink>
                  </div>
                </div>
              </li>

              <li>
                <div className={`dropdown ${direction}`}>
                  <button
                    className="dropdown-toggle menu_bottom_drop"
                    type="button"
                    onClick={() => _dropdown("lang")}>
                    {localization.language}
                  </button>
                  <div
                    className={`dropdown-menu ${dropdow.lang ? "show" : ""}`}>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        _dropdown("lang");
                        _setLang("ar");
                      }}>
                      {localization.langAr}
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        _dropdown("lang");
                        _setLang("en");
                      }}>
                      {localization.langEn}
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        _dropdown("lang");
                        _setLang("fr");
                      }}>
                      {localization.langFr}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
        className={`dropdown-bg ${dropdow.bg ? "show" : ""}`}
        onClick={() => setDropDow({ bg: false })}></div>
      </div>
    </Fragment>
  );
}
