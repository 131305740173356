/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocalization, useAuth } from "../context";
import NavBar from "./Layers/NavBar";
//import { BiUser, BiStar, BiWallet, BiWrench, BiTrashAlt } from "react-icons/bi";
//import { Link } from "react-router-dom";
import ProfileBox from "../components/Layers/ProfileBox";
import Shopping from "./Layers/Shopping";

import { getJson } from "../networking/server";
import Links from "../constants/Links";
import Amount from "./Layers/Amount";

export default function ProfilePage(props) {
  const { localization, setLang, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuerys, setSearchQuery] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const count = 20;

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getFromApi(0).then((response) => {
      if (response.status) {
        if (isMounted) {
          setData(response.results.data);
          setPageCount(Math.ceil(response.results.count_all / count));
          setLoading(false);
        }
      } else {
        if (isMounted) {
          setData([]);
          setPageCount(1);
          setLoading(false);
        }
      }
    });
    setLang(props.match.params.lang);
    return () => (isMounted = false);
  }, [userData, searchQuerys]);

  const getFromApi = async (p) => {
    if (!userData.id) {
      return { status: false };
    }
    const offset = count * p;
    const response = await getJson(
      Links.statistics,
      {
        ...searchQuerys,
        subid: userData.id,
        limit: count,
        offset,
      },
      authToken
    );
    return response;
  };

  const paginate = (p) => {
    setMore(true);
    getFromApi(p).then((response) => {
      if (response.status) {
        //setData([...data, ...newData(response.results.data)]);
        setData([...data, ...response.results.data]);
      }
      setMore(false);
    });
    setPage(p);
  };

  const search = () => {
    //console.log(searchValue);
    setSearchQuery({
      positions: searchValue,
    });
  };

  //console.log(data);

  return (
    <div>
      <NavBar />
      <div className={`container p-0 mt-5 justify-content-center ${direction}`}>
        <div className="row">
          <div className="col-12  col-lg-4">
            <ProfileBox />
          </div>
          <div className="col-12 col-lg-8">
            <div className="card mb-4 ">
              <div className="card-header bg-white ">
                <h6>{localization.profile}</h6>
              </div>

              <Amount
                localization={localization}
                authToken={authToken}
                uid={userData.id}
              />
            </div>

            <Shopping
              localization={localization}
              direction={direction}
              data={data}
              more={more}
              loading={loading}
              search={search}
              searchValue={(event) => setSearchValue(event.target.value)}
              paginate={() => {
                if (page < pageCount) {
                  paginate(page + 1);
                }
              }}
            />

          </div>
        </div>
      </div>
    </div>
  );
}
