import React from "react";
import "../../css/loading.css";
import Loading from "./Loading";
import Config from "../../appConfig";

export default function Shopping(props) {
  const {
    localization,
    //direction,
    data,
    paginate,
    loading,
    more,
    search,
    searchValue,
  } = props;

  const _calcul = (payment, cashback = 0) => {

    payment = parseFloat(payment).toFixed(3);

    if(isNaN(payment)){
      //payment = 1;
      return cashback;
    }

    //console.log(cashback);

    return (payment - (payment * cashback) / 100).toFixed(3);
  };

  //console.log(data);

  return (
    <div className="card mb-4 ">
      <div className="table-search card-header bg-white inline ">
        <h6>{localization.purchases}</h6>
        <div className="search_main">
          <div className="input-group">
            <input
              placeholder={localization.searchInput}
              defaultValue=""
              className="form-control"
              onChange={searchValue}
            />
            <div className="input-group-append">
              <button className="btn btn-link p-0" onClick={search}>
                <img src={`${Config.assetsUrl}/search.svg`} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body pr-0">
        <table
          className={`table beststors ${
            loading || data.length <= 0 ? "" : "table-striped table-responsive"
          }`}>
          <thead className="head_tab">
            <tr>
              <th className="w-25" scope="col">{localization.product}</th>
              <th scope="col">{localization.store}</th>
              <th scope="col">{localization.cashback_percent}</th>
              <th scope="col">{localization.status}</th>
              <th scope="col">{localization.date_created}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
              </tr>
            ) : (
              data.map((item, i) => {
                //console.log(item);
                //return null;
                return Object.values(item.positions).map((product, index) => {
                  return (
                    <tr key={`row-${i}-${index}`} className={`static-row`}>
                      <td>
                        <a
                            href={product.product_url ? product.product_url : "#"}
                            title={product.product_name}
                            target="_blanck">
                            <img
                              src={product.product_image ? product.product_image : Config.assetsUrl + "/noImage.png"}
                              alt={product.product_name}
                            />
                            <p>{product.product_name}</p>
                            <p dir='auto' className="amount">
                              {product.amount} {item.currency}
                            </p>
                          </a>
                      </td>
                      <td>{item.store?.name}</td>
                      <td>
                        <p dir='auto'>{_calcul(product.rate, item.store.cashback)} %</p>
                        <p dir='auto'>
                          {item.cashback}{" "}
                          {item.currency}
                        </p>
                      </td>
                      <td>{localization.productStatus[item.status]}</td>
                      <td>{product.datetime}</td>
                    </tr>
                  );
                });
              })
            )}
          </tbody>
        </table>
        <div className="MoreStors w-100 d-flex justify-content-center p-2 ">
          <button className="morestorebtn" onClick={paginate}>
            {localization.more}
            {(loading || more) && <div className="lds-dual-ring"></div>}
          </button>
        </div>
      </div>
    </div>
  );
}
