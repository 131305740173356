const en = {
  app_name: "",
  locale: "English",
  _locale: "en",
  langAr: "Arabe",
  langFr: "Français",
  langEn: "English",
  language: "Language",
  home: "Home",
  logout: "Logout",
  settings: "Settings",
  loginTitle: "Login to get services",
  signUpTitle: "SignUp to get services",
  recoveryPass: "I forgot password ?",
  recovery: "Recovery",
  register: "Register",
  registerQuestion: "I don't have an account ?",
  update: "Update",
  name: "Full name",
  first_name: "Nom",
  last_name: "Prénom",
  phone2: "Autre numéro de téléphone ",
  //'genre' : 'Le genre',
  genre: [
    "Le genre",
    {
      male: "Mâle",
      female: "Femelle",
    },
  ],
  confirmPassword: "Confirm password",
  date: "Date de naissance",
  account: "Compte",
  connection: "Connexion",
  phone: "Phone number",
  email: "E-mail",
  password: "Password",
  newPassword: "A new password",
  oldPassword: "Your current password",
  PassNotMatch: "Be sure to match the two passwords",
  dontChange: "If you don't want to change your password, leave it blank",
  btnSingUp: "Register",
  newAccount: "New account",
  btnLogin: "Login",
  btnFacebook: "with facebook",
  IhaveAccount: "I have an account, Go back to login",
  EmailNoValid: "Email Already Used or Invalid",
  PhoneNoValid: "Phone number Already Used or Invalid",
  FieldEmpty: "The {key} field is required.",
  MinPassWord: "Password must contain more than 8 characters and special characters",
  categories: "Categories",
  bookmarks: "Favorites",
  about: "About",
  notification: "Notification",
  bookmarkMsg: "Added to Favorites",
  bookmarkMsgAdded: "Added previously",
  noInternet: "Connection error",
  resources: "Resources",
  subscribe: "Join us on Facebook",
  contact: "Contact us on email",
  contactUs: "Contact us",
  developer: "Developer by @DevUp",
  version: "Version",
  more: "Show more",
  notificationAlert:
    "Notification blocked, go to your system settings and enable notifications",
  alert: "Alert",
  social: "Social Media",
  share: "Share",
  rate: "Rate",
  privacy: "Privacy policy",
  Comments: "Comments",
  recoveryPassBtn: "Recovery password",
  recoveryPassTitle: "Enter the email to retrieve the password",
  recoveryPassMsg:
    "A password change message has been sent to your email. Some times it is sent to a spam box",
  recoveryPassMsgError: "There is a problem! Check the email",
  profileUpdate: "Profile Update",
  youInfoUpdated: "Update successfully",
  youInfoUpdatedUnsuccess: "Update unsuccessfully",
  cart: "Shopping cart",
  popularProducts: "Most popular products",
  statusUser: {
    student: "Student",
    employee: "Employee",
    particular: "Particular",
  },
  coupon: "Coupon de réduction",
  coupons: "Coupons",
  allCoupons: "All coupons",
  code: "Code",
  subTotal: "Sub-Total",
  shippingCost: "Shipping Cost",
  total: "Total amount",
  apply: "Apply",
  delete: "Delete",
  cancel: "Cancel",
  ok: "Ok",
  buy: "Take order",
  deleteItme: "Do you really want to delete an item from the cart?",
  open: "Open",
  closed: "Closed",
  Add: "Add",
  MailNotSend: "There is a problem! The email has not been sent",
  yourMailIsReceived : "Thanks. We have received your message and will reply to you as soon as possible",
  RegisterFiled: "Error The new account registration was not completed",
  YourInfoNotValid: "User information is not valid",
  ///////////////////////////////////////////////////////////////////////////////////////////
  FieldEmptyInput: "All fields are required.",
  rateText: "Rate",
  users: "Users",
  profile: "Profile",
  selectAddress: "Choose the delivery address",
  address: "Address",
  //"activity" : "Activité",
  activity: [
    "Activity",
    {
      student: "Student",
      employee: "Employee",
      particular: "Particular",
    },
  ],
  autre: "Other",
  FieldCardEmpty: "The card name field is required.",
  productNotFound: "Product not available \nor unavailable",
  detail: "Product details",
  producttTitle: "Product Features",
  searchInput: "Search",
  search: "Search",
  status: "Status",
  loginUnsuccessful: "Error while logging in",
  active: "Active",
  disactive: "Disactive",
  accountStatus: "Statut du compte",
  maxFileSize: "Are not allowed to upload more than 2Mb size",
  typeFile: "Make sure the file extension (png, gif, jpeg)",
  deleteIsSuccess: "Delete successfully",
  deleteIsError: "Error when deleting",
  addIsSuccess: "Add successfully",
  date_created: "Date Created",
  date_updated: "Date Updated",
  causeDelete: "Mention the reason for deletion",
  FieldCauseDeleteEmpty: "The reason for deletion field is mandatory.",
  num_ccp: "Numéro de ccp",
  //////////////////////////////////////////////////////////////////////////////////////////////
  stores: "Stores",
  store: "Store",
  help: "Help",
  youWelcome: "Welcom",
  updatePassword: "Password change",
  referral: "Referral",
  referralLink: "Referral link",
  loginFacebook: "Login with Facebook",
  loginGoogle: "Login with google",
  error: "Error",
  youAccountCreated:
    "Account created successfully. An activation message has been sent to your email",
  youCountRef: "Registered number via your referral link",
  /////////////////////////////////////////////////////
  msg_pub: "Shop and get back a percentage of your purchases",
  cashback: "Cashback",
  headLineTitle: "Cashback ? Recashit",
  serviceTitle1: "Register with the site",
  serviceTitle2: "Choose offers or stores",
  serviceTitle3: "Withdraw",
  serviceDisc1:
    "Register on the site and choose your favorite store and do the cashback",
  serviceDisc2: "Wait after the shopping process and the cashback will be deposited into your account",
  serviceDisc3: "There is no minimum withdrawal amount, withdraw as soon as the balance becomes available",
  storesSupportIt: "Stores support it",
  browseMore: "Browse the full list now",
  aboutTitle: "About cashback",
  aboutDisc: "When you shop on the Internet, you may encounter many offers, you may choose the best of them and the most affordable and save? But with the cashbacks above the savings you make, you benefit from a return rate for your purchase of up to 30% in most of the international shopping sites supported by your country. Register now, do not be lost. Check out your preferred payment method. " ,
  storesPageTitle: "Browse, shop and get returns",
  shopNow: "Shop Now",
  expiryDate: "Expiry date",
  upTo: "Up to",
  conditions: "Conditions!",
  conditionDescription:"",
  conditionDescriptionMore:"",
  average_approval_time: "Average approval time",
  days: "Days",
  cash_withdrawal_request: "Cash withdrawal request",
  balance_cashback: [
    "Balance",
    {
      pending: "Pending",
      approved: "Approved",
      withdrawn: "Withdrawn",
    },
  ],
  cashback_percent: "Cashback percent",
  product: "Product",
  productStatus: {
    pending: "Pending",
    approved: "Approved",
    declined: "Declined",
    approved_but_stalled: "Approved but stalled",
    "on hold": "On hold",
    confirmed: "Confirmed",
    "confirmed but delayed": "Confirmed but delayed",
    withdrawn: "Withdrawn",
  },
  amount: "The amount to be withdrawn",
  amount_referral: "The amount of the referral",
  methods: "Way of receiving money",
  withdrawal_request_btn: "Send the order",
  withdrawal_request_on_hold: "You have a withdrawal request pending review",
  min_withdrawal: "The minimum withdrawal amount is $ 0.01",
  amount_not_available:
    "You have submitted a request to withdraw an amount greater than the available amount",
  your_request_has_been_received:
    "Your request has been received and you will be notified via e-mail",
  purchases: "Purchases",
  itemLink: "Paste the product link",
  checkProduct: "Earn more by sharing your private link",
  find_out_cashback: "Find the link",
  referrals : "Referrals",
  updateImage : "Edit profile photo",
  pages : {
    "terms-of-services" : "Terms of service",
    "privacy-policy" : "Privacy policy",
    "how-to-buy-with-a-cashback" : "How to buy with a cashback",
  },
  copyRight : 'Recashit.com recashit copyrights reserved',
  subject: 'Subject',
  message : 'Message',

};

export default en;
