import React from "react";

export default function AboutSection(props) {
  const { direction, img, title, subTitle } = props;

  const ElemImage = (
    <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12 mb-4 img">
      <img src={img} alt="" className="img-fluid" />
    </div>
  );

  const Elem = (
    <div className="row mt-5 mb-5 about">
      {direction === "right" && ElemImage}
      <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12 d-flex justify-content-center flex-column ">
        <h2>{title}</h2>
        <p>{subTitle}</p>
      </div>
      {direction !== "right" && ElemImage}
    </div>
  );

  return Elem;
}
