const fileUpload = (files, localization, callback) => {
  if (files.length !== 0) {
    const file = files[0];
    const size = files[0].size;
    const type = files[0].type;

    const types = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/x-png",
      "image/gif",
    ];

    if (size >= 6100000) {
      callback({
        status: false,
        message: localization.maxFileSize,
      });
      return false;
    }

    if (!types.includes(type)) {
      callback({
        status: false,
        message: localization.typeFile,
      });
      return false;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback({
        status: true,
        message: "",
        filebase64: reader.result,
        file: files[0],
      });
    };
    reader.onerror = function (error) {
      //console.log('Error: ', error);
      callback({
        status: false,
        message: error,
      });
    };
  }
  callback({
    status: false,
    message: "",
  });
};
export default fileUpload;
