import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useLocalization } from "../context";
import { FaFacebookSquare, FaGoogle } from "react-icons/fa";
import Links from "../constants/Links";
import { postJson } from "../networking/server";
import Config from "../appConfig";

const SocialMediaLogin = (props) => {
  const { localization } = useLocalization();
  const regions = localStorage.getItem("regions");

  const responseFacebook = (response) => {
    const data = {
      email: response?.email,
      fullname: response?.name,
      image: response.picture?.data.url,
      country: regions ? regions : "DZ",
      register_type: "facebook",
    };
    if (response) {
      _fetch(data);
    }
  };

  const responseGoogle = (response) => {
    const data = {
      email: response.profileObj?.email,
      fullname: response.profileObj?.name,
      image: response.profileObj?.imageUrl,
      country: regions ? regions : "DZ",
      register_type: "google",
    };
    if (response) {
      _fetch(data);
    }
    //console.log(response);
  };

  const _fetch = (data) => {
    data.method = props.type;
    postJson(Links.loginWithSocialMedia, data).then((response) => {
      if (response.status) {
        props.onResponse({
          status: response.status,
          codes: response.results?.code,
          userData: response.results,
        });
      } else {
        props.onResponse({
          status: response.status,
          codes: response.results?.code,
        });
      }
    });
  };

  return (
    <>
      <FacebookLogin
        appId={Config.fbAppId}
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        render={(renderProps) => (
          <button className="btn btn-facebook" onClick={() => renderProps.onClick()}>
            <FaFacebookSquare color="#fff" size="1.5em" />
            <span>{localization.loginFacebook}</span>
          </button>
        )}
      />
      <GoogleLogin
        clientId={Config.googleClientId}
        onSuccess={responseGoogle}
        //onFailure={(response) => responseGoogle(response)}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <button
            className="btn btn-google"
            onClick={() => renderProps.onClick()}
            disabled={renderProps.disabled}>
            <FaGoogle color="#fff" size="1.5em" />
            <span>{localization.loginGoogle}</span>
          </button>
        )}
      />
    </>
  );
};

export default SocialMediaLogin;
