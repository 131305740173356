import React from 'react'
import { useLocalization } from '../../context';
import { Link, } from "react-router-dom";
import Config from '../../appConfig';

export default function Header(props) {

  const { localization, direction } = useLocalization();


  return (
    <div className="header pt-5 pb-5" >
      <div className="bg-rotate-header" />
      <div className={`main_header_home ${direction}`} >
        <div className="header_l" >
          <img src={`${Config.assetsUrl}/Frame.svg`} alt={localization.cashback} title={localization.cashback} />
        </div>
        <div className="header_r direction_flex" >
          <h2 className={direction} dangerouslySetInnerHTML={{__html : localization.msg_pub}} />
          <Link to="/login"><button>{localization.btnSingUp}</button></Link>
        </div>
      </div>
    </div>
  )
}
