/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Layers/NavBar";
import { useLocalization } from "../context";
import { Container, Row, Col } from "react-grid-system";
import CouponItem from "./Layers/CouponItem";
import { getJson } from "../networking/server";
import Links from "../constants/Links";
import Loading from "./Layers/Loading";
import Config from "../appConfig";
import queryString from "query-string";

export default function CouponsPage(props) {
  const regions = localStorage.getItem("regions");
  const { localization, setLang, direction } = useLocalization();
  const [data, setData] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [loadingStore, setLoadingStore] = React.useState(true);
  const [more, setMore] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const count = 12;
  const [storeId, setStoreId] = React.useState(
    parseInt(props.match.params.storeId)
  );
  const queryStringParams = queryString.parse(window.location.search);
  //const pageCount = data.length / count;

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getFromApi(0).then((response) => {
      if (response.status && isMounted) {
        //console.log(response.results);
        if (isMounted) {
          setData(response.results.data);
          setPageCount(Math.ceil(response.results._meta.count / count));
          setLoading(false);
        }
      }
    });
    return () => (isMounted = false);
  }, [storeId, queryStringParams?.q]);

  React.useEffect(() => {
    let isMounted = true;
    getStoreList(isMounted);
    setLang(props.match.params.lang);
    return () => (isMounted = false);
  }, []);

  const getFromApi = async (p) => {
    const offset = count * p;

    const where = {
      regions: regions,
    };

    if (queryStringParams && queryStringParams.q) {
      where.search = queryStringParams.q;
    }

    const response = await getJson(Links.coupons + `/${count}/${offset}`, where);

    if (storeId) {
      if (response.status) {
        //const storeId = parseInt(props.match.params.storeId);
        //console.log(props.match.params.storeId);
        const filter = response.results.data.filter(
          (item) => parseInt(item.w_id) === storeId
        );
        //console.log(filter);
        response.results.data = filter;
      }
    }
    return response;
  };

  const onClick = (gotolink) => {
    window.open(gotolink);
  };

  const paginate = (p) => {
    setMore(true);
    getFromApi(p).then((response) => {
      if (response.status) {
        setData([...data, ...response.results.data]);
      }
      setMore(false);
    });
    setPage(p);
  };

  const getStoreList = (isMounted = true) => {
    setLoadingStore(true);
    getJson(Links.stores + `/${15}/${0}`, {
      regions: regions,
    }).then((response) => {
      if (isMounted && response.status) {
        setStores(response.results.data);
      }
      setLoadingStore(false);
    });
  };

  //console.log(stores)
  return (
    <div>
      <Navbar />

      <div className="image_section">
        <div
          className="head_store"
          style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
          <h3>{localization.coupons}</h3>
        </div>
        <img src={`${Config.assetsUrl}/desktop_allstores.jpg`} alt="" />
      </div>

      <div className={`container mt-5 justify-content-center ${direction}`}>
        <div className="row">
          <div className="col-12 col-sm-4 col-md-4 col-lg-3">
            {loadingStore ? (
              <div className="card mb-4 card-body">
                <Loading height={30} />
              </div>
            ) : (
              <>
                {stores.map((store, index) => {
                
                  let query = "";
                  if(queryStringParams.q){
                    query = "?" + queryString.stringify(queryStringParams)
                  }
                  return (
                    <div key={index} className="card mb-2 coupons-store">
                      <Link
                        to={`/coupons/${store.store_id}/${query}`}
                        onClick={() => setStoreId(parseInt(store.store_id))}>
                        <img src={store.image} alt="" />
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="col-12 col-sm-8 col-md-8 col-lg-9">
            <div className="card mb-4">
              <div className="card-header bg-white inline">
                <h6>{localization.allCoupons}</h6>
              </div>
              <div className="card-body">
                <Container>
                  <Row>
                    {loading ? (
                      <Loading component />
                    ) : (
                      data.map((item, index) => {
                        return (
                          <Col key={index} lg={4} md={6} sm={12} xs={12}>
                            <CouponItem
                              image={item.image}
                              data={item}
                              onClick={onClick}
                              localization={localization}
                            />
                          </Col>
                        );
                      })
                    )}
                    {more && <Loading component />}
                  </Row>
                </Container>
                <div className="MoreStors w-100 d-flex justify-content-center ">
                  <button
                    className="morestorebtn"
                    onClick={() => {
                      if (page < pageCount) {
                        paginate(page + 1);
                      }
                    }}>
                    {localization.more}
                    {(loading || more) && <div className="lds-dual-ring"></div>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
