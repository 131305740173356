/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Navbar from "./Layers/NavBar";
import { useLocalization } from "../context";
import { Container, Row, Col } from "react-grid-system";
import StoreItem from "./Layers/StoreItem";
import Links from "../constants/Links";
import { getJson } from "../networking/server";
import Loading from "./Layers/Loading";
import Config from "../appConfig";
import queryString from "query-string";

export default function StorsPage(props) {
  const regions = localStorage.getItem("regions");
  const { localization, setLang, direction } = useLocalization();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [more, setMore] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const queryStringParams = queryString.parse(window.location.search);

  const count = 9;

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getFromApi(0).then((response) => {
      if (response.status) {
        //console.log(response.results);
        if (isMounted) {
          setData(response.results.data);
          setPageCount(Math.ceil(response.results.count_all / count));
          setLoading(false);
        }
      }else{
        setLoading(false);
      }
    });
    setLang(props.match.params.lang);
  }, [queryStringParams?.q]);

  const getFromApi = async (p) => {
    const where = {
      regions: regions,
      active : 1,
    };

    if (queryStringParams && queryStringParams.q) {
      where.name = queryStringParams.q;
    }
    //console.log(where);

    const offset = count * p;
    const response = await getJson(Links.stores + `/${count}/${offset}`, where);
    return response;
  };

  const paginate = (p) => {
    setMore(true);
    getFromApi(p).then((response) => {
      if (response.status) {
        setData([...data, ...response.results.data]);
      }
      setMore(false);
    });
    setPage(p);
  };

  return (
    <div>
      <Navbar />

      <div className="image_section">
        <div
          className="head_store"
          style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
          <h3>{localization.stores}</h3>
          <h6>{localization.storesPageTitle}</h6>
        </div>
        <img src={`${Config.assetsUrl}/desktop_allstores.jpg`} alt="" />
      </div>

      <div className={`container mt-5 justify-content-center ' ${direction}`}>
        <div className="row">
          <div className="col-12 p-0">
            <div className="card mb-4 ">
              <div className="card-header bg-white inline ">
                <h6>{localization.stores}</h6>
              </div>
              <div className="card-body">
                <Container>
                  <Row>
                    {loading ? (
                      <Loading component={true} />
                    ) : (
                      data.map((item, index) => {
                        //item._data = JSON.parse(item.data);
                        return (
                          <Col key={index} lg={4} md={4} sm={6} xs={12}>
                            <StoreItem
                              key={index}
                              image={item.image}
                              data={{
                                storeId: item.store_id,
                                name: item.name,
                                ...item.data,
                              }}
                              localization={localization}
                            />
                          </Col>
                        );
                      })
                    )}
                    {more && <Loading component={true} />}
                  </Row>
                </Container>

                <div className="MoreStors w-100 d-flex justify-content-center ">
                  <button
                    className="morestorebtn"
                    onClick={() => {
                      if (page < pageCount) {
                        paginate(page + 1);
                      }
                    }}>
                    {localization.more}
                    {(loading || more) && <div className="lds-dual-ring"></div>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
