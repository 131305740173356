/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import Carousel from "react-multi-carousel";
import HeadLine from "./HeadLine";
import StoreItem from "./StoreItem";
import { Link } from "react-router-dom";
import { useLocalization } from "../../context";
import Links from "../../constants/Links";
import { getJson } from "../../networking/server";
import Skeleton from "@yisheng90/react-loading";

export default function HomeStore() {
  const regions = localStorage.getItem("regions");
  const { localization } = useLocalization();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getJson(Links.stores + `/${10}/${0}`, {regions}).then((response) => {
      if (isMounted) {
        //console.log(response.results);
        if (response.status) {
          setData(response.results.data);
          setLoading(false);
        }
      }
    });
    return () => isMounted = false;
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="stors_home">
      <HeadLine
        className="headline_stors"
        title={localization.storesSupportIt}
      />
      <div className="store_container">
        <Carousel infinite={true} responsive={responsive}>
          {loading
            ? [0, 1, 2, 3].map((_i) => (
                <div className="store_item_home" key={_i}>
                  <Skeleton width="100%" height={200} />
                </div>
              ))
            : data.map((item, index) => {
                //item._data = JSON.parse(item.data);
                return (
                  <StoreItem
                    className="store_item_home"
                    key={index}
                    image={item.image}
                    data={{
                      storeId: item.store_id,
                      name: item.name,
                      ...item.data,
                    }}
                    localization={localization}
                  />
                );
              })}
        </Carousel>

        <Link to="/stores">
          <button className="morestorebtnHome">
            {localization.browseMore}
          </button>
        </Link>
      </div>
    </div>
  );
}
