/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import NavBar from "./Layers/NavBar";
import Header from "./Layers/Header";
import Service from "./Layers/Service";
import HeadLine from "./Layers/HeadLine";
import HomeStore from "./Layers/HomeStore";
import About from "./Layers/AboutHome";
import { useLocalization } from "../context";
import Config from "../appConfig";
import Footer from "./Layers/Footer";
import { locales } from "../localization";
import { useHistory } from "react-router-dom";

export default function HomePage(props) {
  const { direction, setLang, localization } = useLocalization();
  const history = useHistory();

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (
        history.location.pathname !== "/" &&
        !locales[props.match.params.lang]
      ) {
        history.push("/404");
      }
    }
    setLang(props.match.params.lang);
    return () => (isMounted = false);
  }, []);

  const service = [
    {
      title: localization.serviceTitle1,
      image: `${Config.assetsUrl}/Group 14.svg`,
      disc: localization.serviceDisc1,
    },
    {
      title: localization.serviceTitle2,
      image: `${Config.assetsUrl}/Group 12.svg`,
      disc: localization.serviceDisc2,
    },
    {
      title: localization.serviceTitle3,
      image: `${Config.assetsUrl}/Group 13.svg`,
      disc: localization.serviceDisc3,
    },
  ];

  return (
    <div className="h-100 w-100">
      <NavBar />

      <Header />

      <HeadLine title={localization.headLineTitle} className="" />

      <div className={`main_services ${direction}`}>
        {service.map((item, index) => (
          <Service
            key={index}
            title={item.title}
            image={item.image}
            disc={item.disc}
          />
        ))}
      </div>

      <HomeStore />

      <About />

      <Footer />
    </div>
  );
}
