import config from "../appConfig.js";
import queryString from "query-string";
import { detect } from "detect-browser";

//console.log(browser);

const getBrowser = () => {
  const browser = detect();
  let browserName = "";
  switch (browser.type) {
    case "bot":
      browserName = null;
      break;
    case "bot-device":
      browserName = null;
      break;
    case "browser":
      browserName = `${browser.name}.${browser.version}.${browser.os}`;
      break;
    case "node":
      browserName = null;
      break;
    default:
      browserName = null;
  }

  return browserName;
};

async function _fetch(links, header = {}) {
  try {
    let response = await fetch(links, header);
    let responseJson = await response.json();
    if (response.status === 200) {
      return responseJson;
    } else {
      return {
        ...responseJson,
        status: false,
      };
    }
  } catch (error) {
    return {
      status: false,
      data: [],
      results: {
        code: [3001],
      },
      error,
    };
  }
}

async function getJson(links, data = [], key = config.appKey) {
  //data.device = getBrowser();
  data = "?" + queryString.stringify(data);
  //console.log(links, data);
  let header = {
    method: "GET",
    headers: {
      [config.nameKay]: key,
    },
  };
  const response = await _fetch(links + data, header);
  return response;
}

async function postJson(links, data, key = config.appKey) {
  data.device = getBrowser();

  var formdata = new FormData();
  for (let key in data) {
    formdata.append(key, data[key]);
  }

  let header = {
    method: "POST",
    headers: {
      [config.nameKay]: key,
    },
    body: formdata,
  };
  const response = await _fetch(links, header);
  return response;
}

export { getJson, postJson, _fetch };
