/// 3000 code error
/// 2000 code success
const Codes = {
  '3000': {
    code: 3000,
    description: 'key not exist',
    text: 'noInternet',
  },
  '3001': {
    code: 3001,
    description: 'There is a problem!',
    text: 'noInternet',
  },
  '3002': {
    code: 3002,
    description: 'User information is not valid',
    text: 'YourInfoNotValid',
  },
  '3003': {
    code: 3003,
    description: 'There is a problem! The email has not been sent',
    text: 'MailNotSend',
  },
  '3004': {
    code: 3004,
    description: 'Error The new account registration was not completed',
    text: 'RegisterFiled',
  },
  '3005': {
    code: 3005,
    description: 'There is a problem! Check the email',
    text: 'recoveryPassMsgError',
  },
  '3006': {
    code: 3006,
    description: 'Email Already Used or Invalid',
    text: 'EmailNoValid',
  },
  '3007': {
    code: 3007,
    description: 'Phone numbre Already Used or Invalid',
    text: 'PhoneNoValid',
  },
  '3008': {
    code: 3008,
    description: 'The {key} field is required.',
    text: 'FieldEmpty',
  },
  '3009': {
    code: 3009,
    description: 'Password should be more than 8 characters',
    text: 'MinPassWord',
  },
  '3010': {
    code: 3010,
    description: '',
    text: 'noInternet',
  },
  '3011': {
    code: 3011,
    description: 'Auth failed password not correct',
    text: 'YourInfoNotValid',
  },
  '3012': {
    code: 3012,
    description: 'Update unsuccessfully',
    text: 'youInfoUpdatedUnsuccess',
  },
  '3013': {
    code : 3013,
    description: '',
    text: 'withdrawal_request_on_hold',
  },
  '3014': {
    code : 3014,
    description: '',
    text: 'min_withdrawal',
  },
  '3015': {
    code : 3015,
    description: '',
    text: 'amount_not_available',
  },
  '2001': {
    code: 2001,
    description:
      'A password change message has been sent to your email. Some times it is sent to a spam box',
    text: 'recoveryPassMsg',
  },
  '2002': {
    code: 2002,
    description: 'Edited successfully',
    text: 'youInfoUpdated',
  },
  '2003': {
    code: 2003,
    description: 'Account Successfully Created',
    text: 'youAccountCreated',
  },
  '2004': {
    code: 2004,
    description:
      'Thanks. We have received your message and will reply to you as soon as possible',
    text: 'yourMailIsReceived',
  },
  '2010': {
    code: 2010,
    description: '',
    text: 'your_request_has_been_received',
  },
};

export default Codes;
