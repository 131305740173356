/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Config from "../../appConfig";

export default function CouponItem(props) {
  const item = props.data;
  const image = props.image;
  const onClick = props.onClick;
  const localization = props.localization;

  //console.log(typeof item.promocode);

  return (
    <div className="store_item">
      <div
        className="coupons_head"
        style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
        <h6 dir='auto'>{item.short_name.toUpperCase()}</h6>
      </div>

      <div className="store_logo_coupons">
        <img src={image} alt="" className="img-fluid" />
      </div>

      <div className="store_title_item">
        <label>{item.name}</label>
        <h4 className="recv_value"> {item.discount ? item.discount : ""}</h4>
      </div>

      <label>
        {localization.expiryDate} {item.date_end}
      </label>
      <a
        className="CodeCoupons btn p-3"
        href={"#"}
        onClick={() => onClick(item.goto_link)}>
        {localization.code} :{" "}
        {item.promocode}
      </a>
    </div>
  );
}
