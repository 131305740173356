import React from "react";
import { Link } from "react-router-dom";

export default function StoreItem(props) {
  const className = props.className ? props.className : "";
  const image = props.image ? props.image : undefined;
  const item = props.data;
  const localization = props.localization;

  return (
    <>
      <div className={`store_item ${className}`}>
        <Link className="store_logo_item p-2" to={`/store/${item.storeId}`}>
          {image && <img src={image} alt="" className="img-fluid" />}
        </Link>

        <div className="store_title_item">
          <label>{item.name}</label>
          <h4 className="recv_value">{item.cashback ? item.cashback : ""}</h4>
          <label>{localization.recovery}</label>
        </div>
        <div className="p-3">
          <Link to={`/store/${item.storeId}`}>
            <button
              className=" w-100 shop_btn p-2">
              {localization.shopNow}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
