import React from 'react'
import Config from '../appConfig';

export default function NotFoundPage() {
    return (
        <div className='center'>
            <img src={`${Config.assetsUrl}/404.png`} alt='' />
        </div>
    )
}
