/* eslint-disable import/no-anonymous-default-export */

//const baseUrl = "https://recashit.meksiabdou.net/";
const baseUrl = "https://recashit.meksiabdou.net/";

export default {
    appKey: '$^=@uM7]9DX9eqK`L={Mz*jcTs@WVz(B&uw2={U',
    nameKay: 'token',
    device: 'web',
    fbAppId: "1798546173627164",
    googleClientId : "783740116954-1b2mqmg4kq9v63loflbl40g42i07lbgj.apps.googleusercontent.com",
    url: baseUrl + 'api',
    homeUrl: baseUrl,
    assetsUrl : baseUrl + 'images'
};