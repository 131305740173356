/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocalization } from "../context";
import NavBar from "./Layers/NavBar";
import { Link } from "react-router-dom";
import { _validation } from "../helps/validation";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Codes from "../constants/codes";
import Config from '../appConfig';

export default function RecoveyPage(props) {
  const { localization, setLang, direction } = useLocalization();

  const [dataInput, setDataInput] = React.useState({
    email: "",
  });

  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLang(props.match.params.lang);
  }, []);

  const getTextFromCodes = (code) => {
    if(!code){
      return "";
    }
    return localization[Codes[code].text];
  };


  const _onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);

    const result = validation();

    const data = { ...dataInput };
    //data.identity = data.email;

    if (result) {
      postJson(Links.forgotPassword, data).then((response) => {
        if (response.status) {
          setSuccess(true);
          setMessage(response.results.code);
          localStorage.setItem("email", data.email);
        } else {
          setError(true);
          setMessage(response?.results?.code);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const _setValues = (event) => {
    setDataInput({
      ...dataInput,
      [event.target.name]: event.target.value,
    });
  };

  const validation = () => {
    for (const key in dataInput) {
      const results = _validation(key, dataInput[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }
    setError(false);
    setMessage("");
    return true;
  };

  return (
    <div className={` main_login ${direction}`}>
      <NavBar />

      <div className="login_section">
        <div className="login_box h_login_box">
          <div className="login_box_head" style={{backgroundImage : `url(${Config.assetsUrl}/backhead.png)`}}>
            <h3>{localization.recoveryPassBtn}</h3>
          </div>

          <div className="login_box_form">
            {(error || success) && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
                role="alert">
                {Array.isArray(message)
                  ? message.map((item, index) => (
                      <p key={index}>{getTextFromCodes(item)}</p>
                    ))
                  : message}
              </div>
            )}
            <form onSubmit={_onSubmit}>
              <input
                type="email"
                name="email"
                defaultValue={dataInput.email}
                placeholder={localization.email}
                onChange={_setValues}
              />

              <button
                className="login_btn_box"
                disabled={loading}
                type="submit">
                {localization.recoveryPassBtn}
                {loading && <div className="lds-dual-ring white"></div>}
              </button>
            </form>
          </div>
          <div className="login_box_link">
            <label>
              <Link to="/login">{localization.IhaveAccount}</Link>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
