/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
//import { Link } from "react-router-dom";
import Navbar from "./Layers/NavBar";
import { useLocalization } from "../context";
//import { Container, Row, Col } from "react-grid-system";
import { getJson } from "../networking/server";
import Links from "../constants/Links";
import Config from "../appConfig";
import Loading from "./Layers/Loading";
import { useHistory } from "react-router-dom";
import { locales } from "../localization";

const Pages = (props) => {
  const { localization, setLang, direction } = useLocalization();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const history = useHistory();
  const page = props.match.params.page;
  const lang = props.match.params.lang;

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);
    setLang(lang);
    //console.log(localization);
    getFromApi({
      page,
      local: lang,
    }).then((response) => {
      if (isMounted) {
        if (response.status) {
          setData(response.results.data[0]);
        } else {
          setData({});
        }
        setLoading(false);
      }
    });
    return () => (isMounted = false);
  }, [page, lang]);

  //console.log(values);

  const getFromApi = async (query) => {
    if (
      !query.page ||
      !query.local ||
      !Object.keys(locales).includes(query.local)
    ) {
      history.push("/404");
      return {
        status: false,
      };
    }
    const response = await getJson(Links.getPages, query);
    return response;
  };

  return (
    <div>
      <Navbar />

      <div className="image_section">
        <div
          className="head_store"
          style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
          <h3>{data.name ? localization.pages[data.name] : ""}</h3>
        </div>
        <img src={`${Config.assetsUrl}/page.png`} alt="" />
      </div>

      <div className={`container mt-5 justify-content-center ${direction}`}>
        <div className="row">
          <div className="col-12">
            <div className="card mb-4 ">
              <div className={`card-body ${direction}`}>
                {loading ? (
                  <Loading data={[1]} height={150} />
                ) : (
                  <div
                    className="col-12"
                    dangerouslySetInnerHTML={{ __html: data.text }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3" >
            <div className=""/>
              </div>*/}
        </div>
      </div>
    </div>
  );
};
export default Pages;
