import { createContext, useContext } from 'react';

export const Context = createContext();

export function useAuth() {
  const auth =  useContext(Context);
  return {
    authToken : auth.authToken,
    userData : auth.userData,
    setAuthUserData: auth.setAuthUserData,
  };
}

export function useLocalization() {
  const local = useContext(Context);
  return {
    setLang : local.setLang,
    localization : local.localization,
    direction : local.direction,
  };
}