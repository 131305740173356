/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { useLocalization, useAuth } from "../../context";
import { _validation } from "../../helps/validation";
import { postJson } from "../../networking/server";
import Links from "../../constants/Links";
import Codes from "../../constants/codes";
import { BiLowVision, BiShowAlt } from "react-icons/bi";
import Swal from "../../constants/swal";

export default function AaccountSettings() {
  const { setAuthUserData, userData, authToken } = useAuth();

  const { localization } = useLocalization();
  const [dataInput, setDataInput] = React.useState({});
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [password, setPassword] = React.useState({
    newPassword: "",
    confirmPassword: "",
  });

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-secondary",
    },
    buttonsStyling: false,
  });

  const fullname = (fullname = "") => {
    const _fullname = fullname.split(" ");
    if (typeof _fullname === "object") {
      return {
        first_name: _fullname[0],
        last_name: fullname.replace(_fullname[0], "").trim(),
      };
    }
    return {
      first_name: fullname,
      last_name: "",
    };
  };

  React.useEffect(() => {
    if (userData) {
      const name = fullname(userData.fullname);
      setDataInput({
        email: userData.email,
        first_name: name.first_name,
        last_name: name.last_name,
        phone: userData.phone,
        country: userData.country,
      });
    }
  }, [userData]);

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return localization[Codes[code].text];
  };

  const update = (data, submitType = "profileUpdate") => {
    swalWithBootstrapButtons.fire({
      title: localization.oldPassword,
      input: "password",
      inputPlaceholder: localization.oldPassword,
      inputAttributes: {
        minlength: 8,
        autocapitalize: "off",
        autocorrect: "off",
        //required: true,
      },
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: localization.update,
      cancelButtonText: localization.cancel,
      showLoaderOnConfirm: true,
      willClose: () => {
        setLoading(false);
      },
      preConfirm: (password) => {
        if (!password) {
          Swal.showValidationMessage(
            localization.FieldEmpty.replace("{key}", localization.password)
          );
          setLoading(false);
          return false;
        }

        const link =
          submitType === "passwordUpdate"
            ? Links.passwordUpdate
            : Links.profileUpdate;

        postJson(link, { ...data, password, id: userData.id }, authToken).then(
          (response) => {
            if (response.status) {
              if (submitType !== "passwordUpdate") {
                setAuthUserData({
                  ...userData,
                  ...data,
                });
              }
              setSuccess(true);
              setMessage(localization.youInfoUpdated);
            } else {
              setError(true);
              setSuccess(false);
              setMessage(response?.results?.code);
            }
            setLoading(false);
          }
        );
      },
      //allowOutsideClick: () => !Swal.isLoading(),
    });
  };
  const _onSubmitUpdate = (event) => {
    event.preventDefault();

    setLoading(true);

    if (!userData.id) {
      return false;
    }

    const result = validation();

    const data = { ...dataInput };
    data.fullname = data.first_name + " " + data.last_name;

    if (result) {
      update(data);
    } else {
      setLoading(false);
    }
  };

  const _onSubmitPassword = (event) => {
    event.preventDefault();

    if (!userData.id) {
      return false;
    }

    setLoading(true);

    const data = { ...password, email : userData.email };
    const result = validation();

    if (result) {
      update(data, "passwordUpdate");
    } else {
      setLoading(false);
    }
  };

  const _setValues = (event) => {
    setDataInput({
      ...dataInput,
      [event.target.name]: event.target.value,
    });
  };

  const _setPassword = (event) => {
    if (event.target.name === "confirmPassword") {
      if (event.target.value !== password.newPassword) {
        setError(true);
        setMessage(localization.PassNotMatch);
      } else {
        setError(false);
        setMessage("");
      }
    }
    setPassword({
      ...password,
      [event.target.name]: event.target.value,
    });
  };
  //console.log(dataInput);

  const validation = () => {
    for (const key in dataInput) {
      const results = _validation(key, dataInput[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }
    setError(false);
    setSuccess(false);
    setMessage("");
    return true;
  };

  return (
    <Fragment>
      {(error || success) && (
        <div className="mb-4">
          <div
            className={success ? "alert alert-success" : "alert alert-danger"}
            role="alert">
            {Array.isArray(message)
              ? message.map((item, index) => (
                  <p key={index}>{getTextFromCodes(item)}</p>
                ))
              : message}
          </div>
        </div>
      )}
      <div className="card mb-4">
        <div className="card-header bg-white inline ">
          <h6>{localization.profileUpdate}</h6>
        </div>

        <div className="card-body">
          <form onSubmit={_onSubmitUpdate}>
            {userData.register_type === "normal" ? (
              <>
                <input
                  className="w-100"
                  dir="auto"
                  type="text"
                  name="first_name"
                  defaultValue={dataInput.first_name}
                  placeholder={localization.first_name}
                  onChange={_setValues}
                />

                <input
                  className="w-100"
                  dir="auto"
                  type="text"
                  name="last_name"
                  defaultValue={dataInput.last_name}
                  placeholder={localization.last_name}
                  onChange={_setValues}
                />
              </>
            ) : (
              <input
                className="w-100"
                dir="auto"
                type="text"
                name="name"
                defaultValue={userData.name}
                placeholder={localization.name}
                onChange={_setValues}
              />
            )}

            <input
              className="w-100"
              dir="auto"
              type="email"
              name="email"
              defaultValue={dataInput.email}
              placeholder={localization.email}
              onChange={_setValues}
            />

            <input
              className="w-100"
              dir="auto"
              type="text"
              name="phone"
              defaultValue={dataInput.phone}
              placeholder={localization.phone}
              onChange={_setValues}
            />

            {/*<select className="w-100" name="country" defaultValue={userData.country}>
                            <option value={213}>Algeria</option>
                        </select>*/}

            <button
              className="login_btn_box w-100"
              disabled={loading}
              type="submit">
              {localization.update}
              {loading && <div className="lds-dual-ring white"></div>}
            </button>
          </form>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header bg-white inline ">
          <h6>{localization.updatePassword}</h6>
        </div>

        <div className="card-body">
          <form onSubmit={_onSubmitPassword}>
            <div className="PasswordInput">
              <input
                type={showPassword.newPassword ? "text" : "password"}
                name="newPassword"
                defaultValue={dataInput.password}
                placeholder={localization.newPassword}
                onChange={_setPassword}
              />
              <button
                type="button"
                onClick={() => {
                  setShowPassword({
                    ...showPassword,
                    newPassword: !showPassword.newPassword,
                  });
                }}>
                {showPassword.newPassword ? (
                  <BiLowVision color="#91A2B7" size="1.3em" />
                ) : (
                  <BiShowAlt color="#91A2B7" size="1.3em" />
                )}
              </button>
            </div>

            <div className="PasswordInput">
              <input
                type={showPassword.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                defaultValue={dataInput.confirmPassword}
                placeholder={localization.confirmPassword}
                onChange={_setPassword}
              />
              <button
                type="button"
                onClick={() => {
                  setShowPassword({
                    ...showPassword,
                    confirmPassword: !showPassword.confirmPassword,
                  });
                }}>
                {showPassword.confirmPassword ? (
                  <BiLowVision color="#91A2B7" size="1.3em" />
                ) : (
                  <BiShowAlt color="#91A2B7" size="1.3em" />
                )}
              </button>
            </div>
            <button
              className="w-100 login_btn_box"
              disabled={loading}
              type="submit">
              {localization.updatePassword}
              {loading && <div className="lds-dual-ring white"></div>}
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
