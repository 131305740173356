/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import NavBar from "./Layers/NavBar";
import { useHistory } from "react-router-dom";
import { useLocalization, useAuth } from "../context";
import { getJson, postJson } from "../networking/server";
import Links from "../constants/Links";
import Config from "../appConfig";
import Loading from "./Layers/Loading";
import { BiClipboard } from "react-icons/bi";

import {
  FacebookShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  //TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  //TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

import { CopyToClipboard } from "react-copy-to-clipboard";

export default function StorePage(props) {
  const regions = localStorage.getItem("regions");
  const { localization, setLang, direction } = useLocalization();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingDeeplink, setLoadingDeeplink] = useState(false);
  const [loadingDeeplinkCheck, setLoadingDeeplinkCheck] = useState(false);

  //const storeName = item?.name?.toLowerCase();
  
  const [value, setValue] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(0);
  const [url, setUrl] = useState(undefined);
  const [urlValue, setUrlValue] = useState("");
  const { authToken, userData } = useAuth();

  const history = useHistory();

  React.useEffect(() => {
    let isMounted = true;

    setLang(props.match.params.lang);

    if (!props.match.params.id) {
      return false;
    }
    getJson(Links.storesById + "/" + props.match.params.id, {
      regions: regions,
    }).then((reponse) => {
      if (isMounted && reponse.status) {
        const data = reponse.results.data;
        getJson(Links.categories, []).then((catgResponse) => {
          //console.log(data);
          if (catgResponse.status) {
            const [_data] = data.map((item) => {
              const catgs = catgResponse.results.data.map((categorie) => {
                return {
                  ...categorie,
                  value: item.categories ? item.categories[categorie.name] : 0,
                };
              });

              return {
                ...item.data,
                store_id: item.store_id,
                name: item.name,
                active: item.active,
                cashback: item.cashback,
                content: item.content,
                categories: catgs.map((_catg) => {
                  return {
                    ..._catg,
                    translate: JSON.parse(_catg.translate),
                  };
                }),
              };
            });
            //console.log(_data);
            setItem(_data);
            setLoading(false);
          }
        });
      }
      //console.log(reponse);
    });
    return () => (isMounted = false);
  }, []);

  //console.log(item);

  const deeplinkGenerator = (url = item.gotolink, win = false) => {
    if (!authToken) {
      setLoadingDeeplinkCheck(false);
      setLoadingDeeplink(false);
      return false;
    }
    postJson(
      Links.deeplink_generator,
      { id: item.store_id, user_id: userData.id, url: url },
      authToken
    ).then((response) => {
      if (response.status) {
        setValue(response.results);
        if (!win) {
          setUrl(response.results);
          document.getElementById("url").value = response.results;
        }
        if (parseInt(item.store_id) !== 6115 && win) {
          window.location.href = response.results;
        }
        setLoadingDeeplinkCheck(false);
        setLoadingDeeplink(false);
      }
      //console.log(response);
    });
  };

  const onClick = () => {
    if (!authToken) {
      history.push(`/login?redirect=store/${item.store_id}`);
      return false;
    }
    setIsPopoverOpen(!isPopoverOpen);
    if (parseInt(item.store_id) !== 6115) {
      setLoadingDeeplink(true);
      deeplinkGenerator(item.gotolink, true);
    }
  };

  const _gotolink = (_value = null) => {
    if (!authToken) {
      history.push(`/login?redirect=store/${item.store_id}`);
      return false;
    }
    if(!_value || _value.toString().trim() === ""){
      setLoadingDeeplinkCheck(false);
      return false;
    }
    const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    _value.replace(urlRegex, (url) => {
      setValue(_value);
      deeplinkGenerator(url, false);
    });
  };

  //console.log(url);

  return (
    <div>
      <NavBar />

      <div className="image_section">
        <div className="head_store">
          <h3>{item.name}</h3>
        </div>
        <img src={`${Config.assetsUrl}/backhead.png`} alt="" />
      </div>

      <div className={`container mt-5 justify-content-center ' ${direction}`}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
            {loading ? (
              <div className="card mb-4">
                <div className="card-body">
                  <Loading data={[1]} height={150} className="w-100" />
                </div>
              </div>
            ) : (
              <div className="card mb-4">
                <div className="card-body justify-content-center d-flex align-items-center  flex-column">
                  <div className="logo_store">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="store_name">
                    <h4>{item.name}</h4>
                  </div>

                  <div className="store_cash">
                    <span>{localization.cashback}</span>
                    <h5>
                      {localization.upTo} {item.cashback}%
                    </h5>

                    <span>{localization.average_approval_time}</span>
                    <h5>
                      {45} {localization.days}
                    </h5>
                  </div>

                  <div className="store_button n w-100">
                    <button onClick={onClick} className="shop_btn w-100 p-2">
                      {localization.shopNow}
                      {loadingDeeplink && (
                        <div className="lds-dual-ring white"></div>
                      )}
                    </button>
                  </div>
                  {parseInt(item.store_id) === 6115 && (
                    <div
                      className="toast"
                      data-autohide="false"
                      style={{
                        opacity: !isPopoverOpen ? 0 : 1,
                        zIndex: isPopoverOpen ? 999 : -999,
                      }}>
                      <div className="toast-header">
                        <button
                          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                          type="button"
                          className="ml-2 mb-1 close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="toast-body">
                        <form onSubmit={(event) => event.preventDefault()}>
                          <label>{localization.itemLink}</label>
                          <input
                            required
                            dir="auto"
                            placeholder={
                              "https://www.example.com/item/1005001412533737.html"
                            }
                            //value={value}
                            autoFocus={true}
                            style={{ width: "100%" }}
                            onChange={(event) => {
                              setLoadingDeeplink(true);
                              _gotolink(event.target.value);
                            }}
                          />
                          <button
                            onClick={() => (window.location.href = value)}
                            className="shop_btn">
                            {localization.shopNow}
                          </button>
                          {loadingDeeplink && (
                            <div className="lds-dual-ring m-0"></div>
                          )}
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-8">
            {loading ? (
              <div className="card">
                <div className="card-body">
                  <Loading data={[1]} height={150} />
                </div>
              </div>
            ) : (
              <>
                <div className="card mb-4">
                  <div className="card-header bg-white inline ">
                    <h6> {localization.checkProduct} </h6>
                  </div>
                  <div className="card-body">
                    <form onSubmit={(event) => event.preventDefault()}>
                      <div className="row m-0">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 p-0 mt-3">
                          <input
                            //required
                            dir="auto"
                            placeholder={
                              "https://www.exmple.com/item/1005001412533737.html"
                            }
                            //value={value}
                            id="url"
                            autoFocus={true}
                            className="w-100 m-0"
                            //style={{ width: "75%", margin: 0 }}
                            onChange={(event) =>
                              setUrlValue(event.target.value)
                            }
                          />
                        </div>
                        <div
                          className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-3"
                          style={{ padding: "0 2px" }}>
                          <button
                            onClick={() => {
                              setLoadingDeeplinkCheck(true);
                              _gotolink(urlValue);
                            }}
                            className="shop_btn ckeck-btn w-100">
                            {localization.find_out_cashback}
                          </button>
                        </div>
                      </div>

                      {loadingDeeplinkCheck ? (
                        <Loading
                          data={[1, 2, 3, 4]}
                          style={{ display: "flex", flexDirection: "row" }}
                          height={45}
                          width={45}
                          circle
                          className="mt-2 m-1"
                        />
                      ) : (
                        url && (
                          <div style={{ padding: "10px 0" }}>
                            <FacebookShareButton url={url} className={"m-1"}>
                              <FacebookIcon size={45} round />
                            </FacebookShareButton>
                            <WhatsappShareButton url={url} className={"m-1"}>
                              <WhatsappIcon size={45} round />
                            </WhatsappShareButton>
                            <FacebookMessengerShareButton url={url} className={"m-1"} appId={Config.fbAppId} >
                              <FacebookMessengerIcon size={45} round />
                            </FacebookMessengerShareButton>
                            {/*<TwitterShareButton url={url} className={"m-1"}>
                              <TwitterIcon size={45} round />
                            </TwitterShareButton>*/}
                            <TelegramShareButton url={url} className={"m-1"}>
                              <TelegramIcon size={45} round />
                            </TelegramShareButton>
                            <ViberShareButton url={url} className={"m-1"}>
                              <ViberIcon size={45} round />
                            </ViberShareButton>
                            <CopyToClipboard text={url}>
                              <button
                                style={{ width: "45px", height: "45px" }}
                                type="button"
                                className="p-0 rounded-circle btn btn-secondary">
                                <BiClipboard size={30} />
                              </button>
                            </CopyToClipboard>
                          </div>
                        )
                      )}
                    </form>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-header bg-white inline ">
                    <h6> {localization.conditions} </h6>
                  </div>
                  <div className="card-body">
                    <div
                      id="conditionDescription"
                      dangerouslySetInnerHTML={{
                        __html: item.content[localization._locale],
                      }}></div>
                  </div>
                </div>
                <div className="card mb-4 ">
                  <div className="card-header bg-white inline ">
                    <h6>{localization.categories}</h6>
                  </div>
                  <div className="card-body p-0">
                    <table className="table table-striped">
                      <tbody>
                        {item.categories.map((_categorie, index) => {
                          if (!parseFloat(_categorie.value)) {
                            return null;
                          }
                          return (
                            <tr key={index}>
                              <td>
                                {_categorie.translate[localization._locale]}
                              </td>
                              <td>{_categorie.value}%</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
