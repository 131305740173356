const ar = {
  app_name: "",
  locale: "العربية",
  _locale: "ar",
  langAr: "العربية",
  langFr: "الفرنسية",
  langEn: "الإنجليزية",
  language: "اللغة",
  home: "الرئيسية",
  logout: "تسجيل الخروج",
  settings: "الإعدادات",
  loginTitle: "تسجيل الدخول",
  signUpTitle: "انشاء حساب جديد",
  recoveryPass: "نسيت كلمة المرور؟",
  recovery: "استرجاع",
  register: "حساب جديد",
  registerQuestion: "ليس لديك حساب ؟ سجل من هنا",
  update: "تعديل",
  name: "الإسم واللقب",
  phone: "رقم الهاتف",
  email: "البريد الإلكتروني",
  password: "كلمة المرور",
  newPassword: "كلمة مرور جديدة",
  oldPassword: "كلمة السر الحالية الخاصة بك",
  PassNotMatch: "تأكد من مطابقة كلمتَي المرور",
  first_name: "الإسم",
  last_name: "اللقب",
  phone2: "رقم هاتف بديل ",
  genre: [
    "النوع",
    {
      male: "الذكر",
      female: "أنثى",
    },
  ],
  confirmPassword: "أكد كلمة المرور",
  date: "تاريخ الميلاد",
  account: "الحساب",
  connection: "الدخول",
  FieldEmptyInput: "جميع الحقول مطلوبة.",
  rateText: "قيم التطبيق",
  users: "المستخدم",
  user: "المستخدم",
  profile: "الملف الشخصي",
  address: "عنوان",
  //"activity" : "Activité",
  activity: [
    "نشاط",
    {
      student: "طالب",
      employee: "موظف",
      particular: "خاص",
    },
  ],
  dontChange: "إذا لم تريد تغيير كلمة المرور اتركه فارغ",
  btnSingUp: "تسجيل حساب",
  newAccount: "انشاء حساب جديد",
  btnLogin: "تسجيل الدخول",
  btnFacebook: "مع الفيسبوك",
  IhaveAccount: "لدي حساب ، ارجع إلى تسجيل الدخول",
  EmailNoValid: "البريد الإلكتروني المستخدم بالفعل أو غير صالح",
  PhoneNoValid: "رقم الهاتف مستخدم بالفعل أو غير صالح",
  FieldEmpty: "حقل  {key} مطلوب.",
  categories: "الأقسام",
  bookmarks: "المفضلة",
  about: "عن الموقع",
  notification: "إشعار",
  bookmarkMsg: "تم إضافة إلى المفضلة",
  bookmarkMsgAdded: "تم إضافتها مسبقا",
  noInternet: "خطأ في الإتصال",
  resources: "مصادر",
  subscribe: "انضم إلينا على فيس بوك",
  contact: "اتصل بنا على البريد الإلكتروني",
  contactUs: "اتصل بنا",
  developer: "مطور بواسطة @DevUp",
  version: "إصدار",
  more: "عرض المزيد",
  notificationAlert:
    "تم حظر الإشعار ، انتقل إلى إعدادات النظام الخاص بك وتمكين الإشعارات",
  alert: "تنبيه",
  social: "وسائل التواصل الاجتماعي",
  share: "مشاركة",
  rate: "قوم",
  privacy: "سياسة الخصوصية",
  Comments: "التعليقات",
  MinPassWord: "يجب أن تحتوي كلمة المرور على أكثر من 8 حروف و رموز خاصة",
  recoveryPassBtn: "استعادة كلمة المرور",
  recoveryPassTitle: "إدخل الايميل لإسترجاع كلمة المرور",
  recoveryPassMsg:
    "تم إرسال رسالة تغير كلمة السر الى إيميلك. بعض مرات ترسل الى علبة سبام",
  recoveryPassMsgError: "يوجد مشكلة ما ! تحقق من البريد الإلكتروني",
  profileUpdate: "تحديث الملف الشخصي",
  youInfoUpdated: "تم تعديل بنجاح",
  youInfoUpdatedUnsuccess: "التحديث غير ناجح",
  cart: "عربة التسوق",
  popularProducts: "المنتجات الأكثر شعبية",
  statusUser: {
    student: "طالب",
    employee: "موظف",
    particular: "خاص",
  },
  coupon: "كوبون خصم",
  coupons: "كوبونات ",
  allCoupons: " كل كوبونات",
  code: "كود",
  subTotal: "المجموع الفرعي",
  total: "مبلغ إجمالي",
  apply: "إستعمل",
  delete: "حذف",
  cancel: "إلغاء",
  ok: "حسنا",
  buy: "مرر الطلب",
  deleteItme: "هل حق تريد حذف عنصر من عربة التسوق؟",
  open: "مفتوح",
  closed: "مغلق",
  Add: "أضف",
  MailNotSend: "هناك مشكلة! لم يتم إرسال البريد الإلكتروني",
  yourMailIsReceived : "شكرا لك. لقد تلقينا رسالتك وسنعاود الاتصال بك في أقرب وقت ممكن",
  RegisterFiled: "خطأ اتناء تسجيل حساب جديد ",
  YourInfoNotValid: "معلومات المستخدم غير صالحة",
  autre: "آخر",
  detail: "تفاصيل",
  searchInput: "بحث",
  search: "بحث",
  status: "الحالة",
  loginUnsuccessful: "خطأ أثناء تسجيل الدخول",
  active: "نشط",
  disactive: "تعطيل",
  accountStatus: "حالة الحساب",
  maxFileSize: "غير مسموح بتحميل حجم أكبر من 2 ميغا بايت",
  typeFile: "تأكد من امتداد الملف (png, gif, jpeg)",
  deleteIsSuccess: "تم الحذف بنجاح",
  deleteIsError: "خطأ عند الحذف",
  addIsSuccess: "أضف بنجاح",
  date_created: "تاريخ الإنشاء",
  date_updated: "التاريخ تعدل",
  causeDelete: "اذكر سبب الحذف",
  FieldCauseDeleteEmpty: "سبب حذف الحقل إلزامي.",
  num_ccp: "رقم Ccp",
  stores: "المتاجر",
  store: "متجر",
  help: "المساعدة",
  youWelcome: "اهلا بك",
  updatePassword: "تعديل كلمة المرور",
  referral: "الإحالة",
  referralLink: "رابط الإحالة",
  loginFacebook: "تسجيل عبر فيسبوك",
  loginGoogle: "تسجيل عبر جوجل",
  error: "خطأ",
  youAccountCreated: "إنشاء الحساب بنجاح. تم إرسال رسالة تفعيل الى إيميلك",
  youCountRef: "عدد مسجلين عن طريق رابط الاحالة الخاص بك",
  //////////////////////////////////////////
  msg_pub: "تسوق و <span> استرجع</span> نسبة من قيمة مشترياتك",
  cashback: "استرداد النقود",
  headLineTitle: "Recashit ? كاشباك",
  serviceTitle1: "سجل بالموقع",
  serviceTitle2: "تسوق كالعادة !",
  serviceTitle3: "إسحب",
  serviceDisc1: "سجل بالموقع و إختر متجرك المفضل و فعل الكاشباك ! ",
  serviceDisc2: "إنتظر بعد عملية التسوق وسوف يتم إيداع الكاش باك في حسابك",
  serviceDisc3: "لا يوجد حد أدنى للسحب ، إسحب فور توفر الرصيد !",
  storesSupportIt: "متاجر ندعمها",
  browseMore: "تصفح القائمة كاملة الآن",
  aboutTitle: "عن كاشباك",
  aboutDisc:
            "عند تسوقك من الأنترنت قد تصادف الكثير من العروض قد تختار الأفضل منهم و الأرخس  وتوفر ؟ لكن مع الكاشباك فوق التوفير الذي تعمله تستفيد من نسبة إسترجاع لعملية الشراء الخاصة تصل إلى 30 % في أغلب مواقع التسوق العالمية التي تدعمها دولتك . سجل الآن  لا تضيع و إسحب في وسيلة الدفع المفضلة لديك",
  storesPageTitle: "تصفح و تسوق و أحصل على استرجاع",
  shopNow: "تسوق الأن",
  expiryDate: "تاريخ انتهاء",
  upTo: "يصل إلى",
  conditions: "الشروط !",
  conditionDescription:
    "<b>من فضلك ركز حول هاته الشروط</b><p>الإسترداد يعمل فقط عندما تسجل دخولك للموقع ، تأكد من تسجيل الدخول في Recashit الإسترداد لن يتم إحتسابه في حالة فتح نزاع مع البائع أحيانا قد يتأخر التأكيد الخاص بالطلبيات بسبب أن موقع {{store-name}}  يحارب التحايل ضده ، قد تتأخر إلى 45 يوم ! رقم الطلبية في موقعنا و في {{store-name}} مختلفين تأكد  من دفعك في نفس الجلسة الطلبيات التي تحجز و تدفع لاحقا لا تحسب في الكاشباك .</p>",
  conditionDescriptionMore:
    "<b>يجب أن تقرأ اته الفقرة !</b><ul><li>إستعمال متصفح بدون إضافات</li><li>أكد من حذف جميع الإضافات و عدم الدخول من أي رابط خارجي أثناء تفعيلك للإسترداد</li></ul>",
  average_approval_time: "متوسط وقت الموافقة",
  days: "يوم",
  cash_withdrawal_request: "طلب سحب المال",
  balance_cashback: [
    "رصيد",
    {
      pending: "قيد الانتظار",
      approved: "تمت الموافقة",
      withdrawn: "المسحوب",
    },
  ],
  cashback_percent: "نسبة استرداد النقود",
  product: "المنتج",
  productStatus: {
    pending: "قيد الانتظار",
    approved: "تمت الموافقة",
    declined: "مرفوض",
    approved_but_stalled: "تمت الموافقة عليها لكنها معطلة",
    "on hold": "معلق",
    confirmed: "تم تأكيد",
    "confirmed but delayed": "تم التأكيد ولكن تأخرت",
    withdrawn: "المسحوب",
  },
  amount: "المبلغ المراد سحبه",
  amount_referral: "المبلغ من الإحالة",
  methods: "طريقة استلام الأموال",
  withdrawal_request_btn: "أرسل الطلب",
  withdrawal_request_on_hold: "يوجد لك طلب سحب في إنتظار مراجعة",
  min_withdrawal: "حد أدنى للسحب هو 0.01 دولار",
  amount_not_available: "لقد قدمت طلبًا لسحب مبلغ أكبر من المبلغ المتوفر",
  your_request_has_been_received: "تم استلام طلبك وسيتم إشعارك عبر الايميل",
  purchases: "المشتريات",
  itemLink: "قم بلصق رابط المنتج",
  checkProduct: " اكسب أكثر من خلال مشاركة رابطك الخاص",
  find_out_cashback: " إكتشف الرابط",
  referrals : "الإحالات",
  updateImage : "تحرير صورة الملف الشخصي",
  pages : {
    "terms-of-services" : "شروط الخدمة",
    "privacy-policy" : "سياسة الخصوصية",
    "how-to-buy-with-a-cashback" : "طريقة الشراء عن طريق الإسترداد",
  },
  copyRight : 'Recashit.com جميع حقوق محفوظة',
  subject: 'الموضوع',
  message : 'الرسالة',

};

export default ar;
