/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocalization, useAuth } from "../context";
import { Col, Row } from "react-grid-system";
import NavBar from "./Layers/NavBar";
import ProfileBox from "../components/Layers/ProfileBox";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Amount from "./Layers/Amount";
import { _validation } from "../helps/validation";
import Codes from "../constants/codes";

const RequestPage = (props) => {
  const { localization, setLang, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  //const [amount, setAmount] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const methods = ["Ccp", "Paysera", "Cashu", "Webmoney"];
  const [values, setValues] = useState({ method: methods[0], detail: '' });
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    //let isMounted = true;
    //setLoading(true);
    setLang(props.match.params.lang);
    //return () => (isMounted = false);
  }, []);

  const getTextFromCodes = (code) => {
    if(!code){
      return "";
    }
    return localization[Codes[code].text];
  };


  const validation = () => {
    for (const key in values) {
      const results = _validation(key, values[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }
    setError(false);
    setMessage("");
    return true;
  };

  const _setToApi = async () => {
    if (!userData.id) {
      return { status: false };
    }
    const response = await postJson(
      Links.withdrawal_request,
      {
        uid: userData.id,
        amount: values.amount,
        method: values.method,
        detail: values.detail,
      },
      authToken
    );
    return response;
  };

  const _onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);
    setSuccess(false);

    const result = validation();

    if (result) {
      _setToApi().then((response) => {
        //console.log(response);
        if (response.status) {
          setSuccess(true);
          setMessage(response?.results?.code);
        } else {
          setError(true);
          setMessage(response?.results?.code);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const _setValues = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const _onSetAmount = (total) => {
    total = parseFloat(total);
    setAmountTotal(parseFloat(total));
    setValues({
      ...values,
      amount: total,
    });
    setLoading(false);
  };

  //console.log(values);

  return (
    <div>
      <NavBar />
      <div className={`container p-0 mt-5 justify-content-center ${direction}`}>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 ">
            <ProfileBox />
          </div>
          <div className="col-12 col-md-6 col-lg-8">
            <div className="card mb-4 ">
              <div className="card-header bg-white ">
                <h6>{localization.profile}</h6>
              </div>

              <Amount
                localization={localization}
                authToken={authToken}
                uid={userData.id}
                onSetAmount={_onSetAmount}
              />
            </div>

            <div className="card mb-4">
              <form onSubmit={_onSubmit} className="cash-request">
                <Row>
                  <Col>
                    {(error || success) && (
                      <div
                        className={
                          success ? "alert alert-success" : "alert alert-danger"
                        }
                        role="alert">
                        {Array.isArray(message)
                          ? message.map((item, index) => (
                              <p className="m-0" key={index}>{getTextFromCodes(item)}</p>
                            ))
                          : message}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <label>{localization.amount}</label>
                    <input
                      className="form-control"
                      type="number"
                      step={0.001}
                      name="amount"
                      max={amountTotal}
                      defaultValue={values?.amount}
                      placeholder={localization.amount}
                      onChange={_setValues}
                    />
                  </Col>
                  <Col lg={12}>
                    <label>{localization.methods}</label>
                    <select
                      className="form-control"
                      name="method"
                      defaultValue={values?.method}
                      onChange={_setValues}>
                      {methods.map((method) => {
                        return (
                          <option key={method} value={method}>
                            {method}
                          </option>
                        );
                      })}
                    </select>
                  </Col>

                  <Col lg={12}>
                    <label>{localization.detail}</label>
                    <textarea
                      className="form-control"
                      name="detail"
                      defaultValue={values?.detail}
                      placeholder={localization.detail}
                      onChange={_setValues}>
                    </textarea>
                  </Col>

                  <Col lg={12}>
                    <button className="button" type="submit" disabled={loading}>
                      {localization.withdrawal_request_btn}
                      {loading && <div className="lds-dual-ring white"></div>}
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPage;
