/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocalization, useAuth } from "../context";
import NavBar from "./Layers/NavBar";
import { Link, useHistory } from "react-router-dom";
import { _validation } from "../helps/validation";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Codes from "../constants/codes";
import SocialMediaLogin from "./SocialMediaLogin";
import Config from "../appConfig";
import queryString from "query-string";

import { BiLowVision, BiShowAlt } from "react-icons/bi";

export default function LoginPage(props) {
  const { localization, setLang, direction } = useLocalization();
  const [dataInput, setDataInput] = React.useState({
    email: "",
    password: "",
  });
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const { setAuthUserData, authToken } = useAuth();
  const history = useHistory();
  const queryStringParams = queryString.parse(history.location.search);

  //const
  React.useEffect(() => {
    let isMounted = true;
    setLang(props.match.params.lang);
    if (authToken) {
      if (isMounted) {
        if (queryStringParams && queryStringParams.redirect) {
          history.push(`/${queryStringParams.redirect}`);
          return false;
        }
        history.push("/profile");
      }
    }
    return () => (isMounted = false);
  }, [authToken]);

  const getTextFromCodes = (code) => {
    if(!code){
      return "";
    }
    return localization[Codes[code].text];
  };

  const _onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);

    const result = validation();

    const data = { ...dataInput };
    data.identity = data.email;

    if (result) {
      postJson(Links.login, data).then((response) => {
        //console.log(response);
        if (response.status) {
          setAuthUserData(response.results);
        } else {
          setError(true);
          setMessage(response?.results?.code);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const _setValues = (event) => {
    setDataInput({
      ...dataInput,
      [event.target.name]: event.target.value,
    });
  };

  const validation = () => {
    for (const key in dataInput) {
      const results = _validation(key, dataInput[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }
    setError(false);
    setMessage("");
    return true;
  };

  return (
    <div className={`main_login ${direction}`}>
      <NavBar />

      <div className="login_section">
        <div className="login_box h_login_box">
          <div
            className="login_box_head"
            style={{
              backgroundImage: `url(${Config.assetsUrl}/backhead.png)`,
            }}>
            <h3>{localization.loginTitle}</h3>
          </div>

          <div className="login_box_form">
            {error && (
              <div className="alert alert-danger" role="alert">
                {Array.isArray(message)
                  ? message.map((item, index) => (
                      <p key={index}>{getTextFromCodes(item)}</p>
                    ))
                  : message}
              </div>
            )}
            <form onSubmit={_onSubmit}>
              <input
                type="email"
                name="email"
                defaultValue={dataInput.email}
                placeholder={localization.email}
                onChange={_setValues}
              />
              <div className="PasswordInput">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  defaultValue={dataInput.password}
                  placeholder={localization.password}
                  onChange={_setValues}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <BiLowVision color="#91A2B7" size="1.3em" />
                  ) : (
                    <BiShowAlt color="#91A2B7" size="1.3em" />
                  )}
                </button>
              </div>
              <button
                className="login_btn_box"
                type="submit"
                disabled={loading}>
                {localization.btnLogin}
                {loading && <div className="lds-dual-ring white"></div>}
              </button>
            </form>
            <SocialMediaLogin
              type="login"
              onResponse={(data) => {
                if (data.status) {
                  //console.log(data.userData);
                  setAuthUserData(data.userData);
                } else {
                  setError(true);
                  setMessage(data.codes);
                }
              }}
            />
          </div>

          <div className="login_box_link">
            <label>
              <Link to="/recovery">{localization.recoveryPass}</Link>
            </label>
            <label>
              <Link to="/signup">{localization.registerQuestion}</Link>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
