import React from "react";
import Skeleton from "@yisheng90/react-loading";
import { Col } from "react-grid-system";

const Loading = (props) => {
  const data = props.data ? props.data : [0, 1, 2];
  const className = props.className ? props.className : "";
  const height = props.height ? props.height : 200;
  const width = props.width ? props.width : "100%";
  const circle = props.circle ? true : false;
  const style = props.style ? props.style : {display: "contents"};

  const Component = !props.component
    ? (props) => <div {...props} />
    : (props) => <Col lg={4} md={4} sm={12} xs={12} {...props} />;

  return (
    <div style={style}>
      {data.map((_i) => (
        <Component className={className} key={_i}>
          <Skeleton width={width} height={height} circle={circle} />
        </Component>
      ))}
    </div>
  );
};

export default Loading;
