/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getJson } from "../../networking/server";
import Links from "../../constants/Links";
import Loading from "./Loading";

const Amount = ({ localization, uid, authToken, onSetAmount }) => {
  const _onSetAmount = onSetAmount ? onSetAmount : () => null;
  const [loading, setLoading] = useState(true);

  const [amount, setAmount] = useState({
    withdrawn: "0.00",
    approved: "0.00",
    pending: "0.00",
  });

  useEffect(() => {
    let isMounted = true;
    //setLoading(true);
    if (uid) {
      getJson(Links.getAmount, { subid: uid }, authToken).then((response) => {
        if (response.status && isMounted) {
          if (isMounted) {
            //console.log(response);
            setAmount(response.results);
            _onSetAmount(response.results.approved);
            setLoading(false);
          }
        }
      });
    }
    return () => (isMounted = false);
  }, [uid]);

  return (
    <div className="card-body">
      {loading ? (
        <Loading data={[0]} />
      ) : (
        Object.keys(amount).map((key, index) => {
          const w = index === 0 ? "w-100" : "w-50";

          return (
            <div
              key={key}
              className={`amounts jumbotron jumbotron-fluid mb-0 pt-4 pb-4 ${w}`}
              style={{ float: "left", borderRadius: 0 }}>
              <div className="container d-flex  justify-content-center align-items-center flex-column">
                <h6>
                  {localization.balance_cashback[0]}{" "}
                  {localization.balance_cashback[1][key]}
                </h6>
                <h2 dir="auto">${amount[key]}</h2>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Amount;
