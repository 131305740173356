/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Navbar from "./Layers/NavBar";
import { useLocalization } from "../context";
import Config from "../appConfig";
import AboutSection from "./Layers/AboutSection";
import Footer from "./Layers/Footer";

const AboutPage = (props) => {
  const { localization, setLang, direction } = useLocalization();
  //const [loading, setLoading] = React.useState(true);

  const [data, setData] = React.useState([
    {
      image: `${Config.assetsUrl}/about.png`,
      title: localization.aboutTitle,
      subTitle: localization.aboutDisc,
    },
    /*{
      image: `${Config.assetsUrl}/about_shop.png`,
      subTitle: "",
      title: "",
    },
    {
      image: `${Config.assetsUrl}/aboutBack.png`,
      subTitle: "",
      title: "",
    },*/
  ]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLang(props.match.params.lang);
    }
    return () => (isMounted = false);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="image_section">
        <div
          className="head_store"
          style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
          <h3>{localization.about}</h3>
          <h6>{localization.storesPageTitle}</h6>
        </div>
        <img src={`${Config.assetsUrl}/aboutHead.png`} alt="" />
      </div>
      <div className={`container d-flex flex-column ${direction}`}>
        {data.map((item, index) => (
          <AboutSection
            key={index}
            img={item.image}
            title={item.title}
            subTitle={item.subTitle}
            direction={index % 2 !== 1 ? "right" : "left"}
          />
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
