import React from 'react'

export default function Service(props) {
    return (
        <div className="service">
        <div className="service_icon" >
            <img src={`${props.image}`}  alt="" />
        </div>
        <div className="service_name" ><h5>{props.title}</h5></div>
        <div className="service_disc" ><p>{props.disc}</p></div>
    </div>
    )
}
