/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocalization } from "../context";
import NavBar from "./Layers/NavBar";
import { useHistory } from "react-router-dom";
import { _validation } from "../helps/validation";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Codes from "../constants/codes";
import Config from '../appConfig';
import { BiLowVision, BiShowAlt } from "react-icons/bi";
import queryString from "query-string";

export default function ResetPasswordPage(props) {
  const { localization, direction, setLang } = useLocalization();

  const history = useHistory();

  const [dataInput, setDataInput] = React.useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const queryStringParams = queryString.parse(window.location.search);
  const codeActive = queryStringParams?.token;

  const getTextFromCodes = (code) => {
    if(!code){
      return "";
    }
    return localization[Codes[code].text];
  };

  const getId = async () => {
    const _email = await localStorage.getItem("email");
    if (_email) {
      return _email;
    }
    return null;
  };

  React.useEffect(() => {
    setLoading(true);
    if (codeActive) {
      getId().then((_email) => {
        if (_email) {
          setEmail(_email);
        }
        setLoading(false);
      });
    }
    setLang(props.match.params.lang);
  }, []);

  //console.log(dataInput);

  const _onSubmit = (event) => {
    if (!codeActive) {
      return false;
    }

    event.preventDefault();

    setLoading(true);
    setError(false);

    const result = validation();

    const data = { ...dataInput };
    if (email) {
      data.email = email;
      data.token = codeActive;
    }

    if (result) {
      postJson(Links.resetPassword, data).then(
        (response) => {
          if (response.status) {
            //setAuthUserData(_response.results);
            //history.push('/profile');
            //console.log(_response);
            setSuccess(true);
            setMessage(response.results.code);
            setTimeout(() => {
              history.push("/login");
            }, 2000);
          } else {
            setError(true);
            setMessage(response?.results?.code);
          }
          //console.log(response);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const _setValues = (event) => {
    if (event.target.name === "confirmPassword") {
      if (event.target.value !== dataInput.password) {
        setError(true);
        setMessage(localization.PassNotMatch);
      } else {
        setError(false);
        setMessage("");
      }
    }
    setDataInput({
      ...dataInput,
      [event.target.name]: event.target.value,
    });
  };

  const validation = () => {
    for (const key in dataInput) {
      const results = _validation(key, dataInput[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }

    if (dataInput.confirmPassword !== dataInput.password) {
      setError(true);
      setMessage(localization.PassNotMatch);
      return false;
    }

    setError(false);
    setMessage("");
    return true;
  };

  return (
    <div className={` main_login ${direction}`}>
      <NavBar />

      <div className="login_section">
        <div className="login_box h_login_box">
          <div className="login_box_head" style={{backgroundImage : `url(${Config.assetsUrl}/backhead.png)`}}>
            <h3>{localization.recoveryPassBtn}</h3>
          </div>

          <div className="login_box_form">
            {(error || success) && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
                role="alert">
                {Array.isArray(message)
                  ? message.map((item, index) => (
                      <p key={index}>{getTextFromCodes(item)}</p>
                    ))
                  : message}
              </div>
            )}
            <form onSubmit={_onSubmit}>
              <div className="PasswordInput">
                <input
                  type={showPassword.password ? "text" : "password"}
                  name="password"
                  defaultValue={dataInput.password}
                  placeholder={localization.password}
                  onChange={_setValues}
                />
                <button
                  type="button"
                  onClick={() => {
                    setShowPassword({
                      ...showPassword,
                      password: !showPassword.password,
                    });
                  }}>
                  {showPassword.password ? (
                    <BiLowVision color="#91A2B7" size="1.3em" />
                  ) : (
                    <BiShowAlt color="#91A2B7" size="1.3em" />
                  )}
                </button>
              </div>

              <div className="PasswordInput">
                <input
                  type={showPassword.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  defaultValue={dataInput.confirmPassword}
                  placeholder={localization.confirmPassword}
                  onChange={_setValues}
                />
                <button
                  type="button"
                  onClick={() => {
                    setShowPassword({
                      ...showPassword,
                      confirmPassword: !showPassword.confirmPassword,
                    });
                  }}>
                  {showPassword.confirmPassword ? (
                    <BiLowVision color="#91A2B7" size="1.3em" />
                  ) : (
                    <BiShowAlt color="#91A2B7" size="1.3em" />
                  )}
                </button>
              </div>
              <button
                className="login_btn_box"
                disabled={loading}
                type="submit">
                {localization.recoveryPassBtn}
                {loading && <div className="lds-dual-ring white"></div>}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
