
const _validation = (key, value, localization) => {
  if (value.toString().trim() === '') {
    const _text = localization[key] ?
      localization.FieldEmpty.replace("{key}", Array.isArray(localization[key]) ? localization[key][0] : localization[key])
      : localization.FieldEmptyInput;
    return {
      status: false,
      onSubmit: false,
      typeInput: key,
      validation: _text,
    };
  } else {
    if (
      key === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      return {
        status: false,
        onSubmit: false,
        typeInput: key,
        validation: localization.EmailNoValid,
      };
    } else if (key === 'phone' && !/^[0]{1}[5-7]{1}[0-9]{8}$/m.test(value)) {
      return {
        status: false,
        onSubmit: false,
        typeInput: key,
        validation: localization.PhoneNoValid,
      };
    }
    else if (key === 'password' && value.length < 8) {
      return {
        status: false,
        onSubmit: false,
        typeInput: key,
        validation: localization.MinPassWord,
      };
    }
  
    return {
      status: true,
      typeInput: '',
      validation: '',
      onSubmit: true,
    };
  }
};


const validationForm = (data, localization) => {

  let validation = [];
  const passwordLength = 8;
  
  for(let key in data)
  {
    const value = data[key];
    //console.log(value);
    if (value.toString().trim() === '') {
      const _text = localization[key] ?
        localization.FieldEmpty.replace("{key}", Array.isArray(localization[key]) ? localization[key][0] : localization[key])
        : localization.FieldEmptyInput;
        validation[key] = {
          status: false,
          typeInput: key,
          validation: _text,
        };
    } else {
      if (
        key === 'email' &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ) {
        validation[key] = {
          status: false,
          typeInput: key,
          validation: localization.EmailNoValid,
        };
      } else if (key === 'phone' && !/^[0]{1}[5-7]{1}[0-9]{8}$/m.test(value)) {
        validation[key] = {
          status: false,
          typeInput: key,
          validation: localization.PhoneNoValid,
        };
      }
      else if (key === 'password' && value.length < passwordLength) {
        validation[key] = {
          status: false,
          typeInput: key,
          validation: localization.MinPassWord,
        };
      }else{
        validation[key] = {
          status: true,
          typeInput: '',
          validation: '',
        };
      }
    }
  }
  return validation;
}


export { _validation, validationForm };
