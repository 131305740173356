/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import { BiUser, BiLink, BiMoney, BiEdit } from "react-icons/bi";
import { useLocalization, useAuth } from "../../context";
import Swal from "../../constants/swal";
import fileUpload from "../../helps/uploadFile";
import { postJson } from "../../networking/server";
import Links from "../../constants/Links";
import Config from "../../appConfig";

export default function ProfileBox() {
  const { localization } = useLocalization();
  const { userData, authToken, setAuthUserData } = useAuth();
  const refInput = React.createRef(null);
  const dirUpload = Config.homeUrl + "uploads/?image=";
  const [image, setImage] = React.useState(dirUpload + "profile.png");
  const id = userData.id;

  //console.log(userData);

  React.useEffect(() => {
    if (userData.image) {
      setImage(dirUpload + userData.image);
    }
  }, [dirUpload, userData]);

  const _fileUpdoad = (event) => {
    fileUpload(event.target.files, localization, (result) => {
      if (result.status) {
        //const file = window.btoa(result.file).replace("==","");
        //console.log(file);
        if (result.filebase64) {
          postJson(
            Links.profileUpdateImage,
            { id: id, image: result.file },
            authToken
          ).then((response) => {
            //console.log(response);
            if (response.status) {
              //setImage(dirUpload+response.results.image);
              setAuthUserData({
                token: authToken,
                ...userData,
                image: response.results.image,
              });
              setImage(result.filebase64);
              return true;
            } else {
              Swal.fire(localization.error, result.message, "error");
            }
            setImage(dirUpload + userData.image);
          });
        }
      } else {
        if (result.message) {
          Swal.fire(localization.error, result.message, "error");
        }
        setImage(dirUpload + userData.image);
      }
    });
  };

  return (
    <div className="card mb-4  ">
      <div className="row m-0">
      <div className="p-0 col-lg-12 col-md-6 col-sm-5">
        <div
          className="head_card_user"
          style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
          <h6>{localization.youWelcome}</h6>
          <img
            className="image-pofile rounded-circle border"
            alt=""
            src={image}
            title={localization.updateImage}
            onClick={() => {
              refInput.current.click();
            }}
          />
          <input
            type="file"
            ref={refInput}
            onChange={_fileUpdoad}
            hidden
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
          />
          <h5>{userData.name}</h5>
        </div>
      </div>

      <div className="p-0 col-lg-12 col-md-6 col-sm-7">
        <ul className="list-group list-group-flush w-100 pr-0 ">
          <li className="list-group-item w-100  ">
            <NavLink activeClassName="active" to="/profile">
              <button type="button" className="btn w-100  profile_btn ">
                <div className="icon_profile_item">
                  <BiUser color="#fffff" size="1.5em" />
                </div>

                <div className="title_profile_item">{localization.profile}</div>
              </button>
            </NavLink>
          </li>

          <li className="list-group-item w-100  ">
            <NavLink activeClassName="active" to="/account-settings">
              <button type="button" className="btn w-100  profile_btn ">
                <div className="icon_profile_item">
                  <BiEdit color="#fffff" size="1.5em" />
                </div>
                <div className="title_profile_item">
                  {localization.profileUpdate}
                </div>
              </button>
            </NavLink>
          </li>

          <li className="list-group-item w-100  ">
            <NavLink activeClassName="active" to="/referrals">
              <button type="button" className="btn w-100  profile_btn ">
                <div className="icon_profile_item">
                  <BiLink color="#fffff" size="1.5em" />
                </div>
                <div className="title_profile_item">
                  {localization.referrals}
                </div>
              </button>
            </NavLink>
          </li>
          <li className="list-group-item w-100  ">
            <NavLink activeClassName="active" to="/request-cash">
              <button type="button" className="btn w-100  profile_btn ">
                <div className="icon_profile_item">
                  <BiMoney color="#fffff" size="1.5em" />
                </div>
                <div className="title_profile_item">
                  {localization.cash_withdrawal_request}
                </div>
              </button>
            </NavLink>
          </li>
        </ul>
      </div>
      </div>
    </div>
  );
}
