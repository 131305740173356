/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ProfileBox from '../components/Layers/ProfileBox'
import NavBar from './Layers/NavBar';
import AccountSettings from './Layers/AccountSettings';
import { useLocalization } from '../context';


export default function EditeProfilePage(props) {

    const {setLang, direction} = useLocalization();

    React.useEffect(() => {
        setLang(props.match.params.lang);
    }, []);


    return (
        <div>
            <NavBar />
            <div className={`container p-0 mt-5 justify-content-center ' ${direction}`} >

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <ProfileBox />
                    </div>
                    <div className="col-12 col-md-6 col-lg-8">
                        <AccountSettings />
                    </div>

                </div>

            </div>
        </div>
    )
}
