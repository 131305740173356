/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
//import { Link } from "react-router-dom";
import Navbar from "./Layers/NavBar";
import { useLocalization } from "../context";
//import { Container, Row, Col } from "react-grid-system";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Config from "../appConfig";
import { _validation } from "../helps/validation";
import Codes from "../constants/codes";

import { BiMailSend } from "react-icons/bi";
import Footer from "./Layers/Footer";

const ContactPage = (props) => {
  const { localization, setLang, direction } = useLocalization();
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState({
    fullname: "",
    email: "",
    subject: "",
    message: "",
  });

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLang(props.match.params.lang);
    }
    return () => (isMounted = false);
  }, []);

  const _setValues = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const getTextFromCodes = (code) => {
    if(!code){
      return "";
    }
    return localization[Codes[code].text];
  };

  const validation = () => {
    for (const key in data) {
      const results = _validation(key, data[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }
    setError(false);
    setMessage("");
    return true;
  };

  const onCantact = (event) => {
    event.preventDefault();

    setLoading(true);
    setSuccess(false);
    setError(false);

    let result = validation();

    if (result) {
      postJson(Links.contact, data).then((response) => {
        if (response.status) {
          setSuccess(true);
          event.target.reset();
          setData({});
        } else {
          setError(true);
        }
        setMessage(response?.results?.code);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />

      <div className="image_section">
        <div
          className="head_store"
          style={{ backgroundImage: `url(${Config.assetsUrl}/backhead.png)` }}>
          <h3>{localization.contact}</h3>
        </div>
        <img src={`${Config.assetsUrl}/page.png`} alt="" />
      </div>

      <div
        className={`container mt-5 justify-content-center ${direction} mb-5 `}>
        <div className="row">
          <div className="col-sm-12 col-md-6">
          {(error || success) && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
                role="alert">
                {Array.isArray(message)
                  ? message.map((item, index) => (
                      <p key={index}>{getTextFromCodes(item)}</p>
                    ))
                  : message}
              </div>
            )}
            <form onSubmit={onCantact}>
              <input
                className="w-100"
                name="fullname"
                onChange={_setValues}
                placeholder={localization.name}
              />
              <input
                className="w-100"
                name="email"
                onChange={_setValues}
                placeholder={localization.email}
              />
              <input
                className="w-100"
                name="subject"
                onChange={_setValues}
                placeholder={localization.subject}
              />
              <textarea
                className="w-100 contact_text"
                onChange={_setValues}
                name="message"
                placeholder={localization.message}></textarea>
              <button className="btn btn_submit">
                {localization.contactUs}
                {loading && <div className="lds-dual-ring white"></div>}
              </button>
            </form>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="contact_items mt-4">
              <div className="contact_item">
                <div className="contact_value">
                  <h6>contact@recashit.com</h6>
                </div>
                <div className="icon">
                  <BiMailSend size="30" />
                </div>
              </div>

              {/*<div className="contact_item">
                <div className="contact_value">
                  <h6>/</h6>
                </div>
                <div className="icon">
                  <BiMap size="30" />
                </div>
                  </div>*/}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default ContactPage;
