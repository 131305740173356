import React from 'react'
import Config from "../../appConfig";
import { useLocalization } from "../../context";

const Footer = () => {

    const { localization } = useLocalization();

    return (
 

<div className="main_footer">

        <div className="container ">
                <div className="row ">

                    <div className=" col-sm-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center pb-3 pt-3  " >
                        <img src={`${Config.assetsUrl}/logo.png`} alt={localization.cashback} title={localization.cashback} />
                    </div>

                    <div className=" col-sm-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center pb-3 pt-3  ">
                        <label>{localization.copyRight} ©</label>
                    </div>

                    <div className=" col-sm-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center pb-3 pt-3  ">
                        <label>{localization.developer} </label>
                    </div>

                </div>
        </div>

</div>


    )
}

export default Footer