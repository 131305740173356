/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import ProfilePage from "./components/ProfilePage";
import ReferralsPage from "./components/ReferralsPage";
import ContactPage from "./components/ContactPage";
import AboutPage from "./components/AboutPage";
import Pages from "./components/Pages";

import PrivateRoute from "./privateRoute";
import { Context } from "./context";

import { _localization, locales } from "./localization";

import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css";
import "./css/app.css";

import SignUpPage from "./components/SignUpPage";
import RecoveryPage from "./components/RecoveryPage";
import ResetPasswordPage from "./components/ResetPasswordPage";

import { postJson, getJson } from "./networking/server";
import Links from "./constants/Links";
import EditeProfilePage from "./components/EditeProfilePage";
import StoresPage from "./components/StoresPage";
import StorePage from "./components/StorePage";
import CouponsPage from "./components/CouponsPage";
import RequestPage from "./components/RequestPage";

import queryString from "query-string";
import NotFoundPage from "./components/NotFoundPage";

function App() {
  const existingToken = localStorage.getItem("token");
  const [authToken, setAuthToken] = React.useState(existingToken);
  const [userData, setUserData] = React.useState({});
  const [localization, setLocalization] = React.useState(_localization("ar"));
  const [direction, setDirection] = React.useState("rtl");
 
  const queryStringParams = queryString.parse(window.location.search);

  React.useEffect(() => {
    let isMounted = true;

    if (queryStringParams && queryStringParams.ref) {
      if (isMounted) {
        localStorage.setItem("ref", queryStringParams.ref);
      }
    }

    if (authToken) {
      postJson(Links.getUserByToken, { token: authToken }, authToken).then(
        (response) => {
          if (isMounted) {
            if (response.status) {
              const data = {
                ...response.results,
                token: authToken,
              };
              _setUserData(data);
            } else {
              _setUserData({});
            }
          }
        }
      );
    }
    return () => (isMounted = false);
  }, []);

  React.useEffect(() => {
    localStorage.setItem("regions", "DZ");
    getJson(Links.localisation).then((response) => {
      //console.log(response.results.countryCode);
      if (response.status) {
        localStorage.setItem("regions", response.results.countryCode);
      }
    });
  }, []);

  const _setUserData = (data) => {
    if (data.token) {
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      if (data.fullname) {
        data.name = data.fullname;
      }
      setUserData(data);

      //console.log(data);
    } else {
      localStorage.removeItem("token");
      setAuthToken(undefined);
    }
    //console.log(data);
  };

  const getLang = async () => {
    const local = await localStorage.getItem("lang");
    if (local) {
      return JSON.parse(local);
    } else {
      return undefined;
    }
  };

  const setLang = async (local) => {
    await localStorage.setItem("lang", JSON.stringify(local));
  };


  const _setLocalization = (lang) => {
    let local = {};

    if (lang && lang === "ar") {
      local = {
        lang: "ar",
        direction: "rtl",
      };
    } else if (lang && lang !== "ar") {
      if (!locales[lang]) {
        lang = "fr";
      }
      local = {
        lang: lang,
        direction: "ltr",
      };
    } else {
      getLang().then((_local) => {
        if (_local !== undefined) {
          local = {
            lang: _local.lang,
            direction: _local.direction,
          };
        } else {
          local = {
            lang: "ar",
            direction: "rtl",
          };
        }
        //console.log(local);
        setDirection(local.direction);
        setLocalization(_localization(local.lang));
        setLang(local).then();
      });
      return false;
    }
    setDirection(local.direction);
    setLocalization(_localization(local.lang));
    setLang(local).then();
  };

  const Logout = () => {
    let isMounted = true;
    const history = useHistory();
    React.useEffect(() => {
      postJson(Links.logout, { login: userData.id }).then(
        (response) => {
          if (response && isMounted) {
            setTimeout(() => {
              _setUserData({});
              history.push("/login");
            }, 150);
          }
        }
      );
      return () => (isMounted = false);
    }, []);
    return <></>;
  };


  return (
    <Context.Provider
      value={{
        authToken,
        userData,
        setAuthUserData: _setUserData,
        localization,
        direction,
        setLang: _setLocalization,
      }}>
      <BrowserRouter className="App">
        <Switch>

          <Route exact path="/home/:lang?" component={HomePage} />
          <Route exact path="/page/:page/:lang" component={Pages} />
          <Route exact path="/login/:lang?" component={LoginPage} />
          <Route exact path="/signup/:lang?" component={SignUpPage} />
          <Route exact path="/recovery/:lang?" component={RecoveryPage} />
          <Route exact path="/contact/:lang?" component={ContactPage} />
          <Route exact path="/about/:lang?" component={AboutPage} />
          <Route
            exact
            path="/reset-password/:lang?"
            component={ResetPasswordPage}
          />
          {/** Start Privat Route */}
          <PrivateRoute exact path="/profile/:lang?" component={ProfilePage} />
          <PrivateRoute
            exact
            path="/referrals/:lang?"
            component={ReferralsPage}
          />
          <PrivateRoute
            exact
            path="/account-settings/:lang?"
            component={EditeProfilePage}
          />
          <PrivateRoute
            exact
            path="/request-cash/:lang?"
            component={RequestPage}
          />
          {/** End Privat Route */}
          <Route exact path="/stores/:lang?" component={StoresPage} />
          <Route exact path="/store/:id/:lang?" component={StorePage} />
          {/*<Route exact path="/coupons/:lang?" component={CouponsPage} />*/}
          <Route
            exact
            path="/coupons/:storeId?/:lang?"
            component={CouponsPage}
          />
          <Route exact path="/logout/:lang?" component={Logout} />
          <Route exact path="/404" component={NotFoundPage} />
          <Route exact path="/:lang?" component={HomePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
