/* eslint-disable import/no-anonymous-default-export */
import config from "../appConfig.js";
const baseUri = config.url;

export default {
  baseUri,
  login: baseUri + "/login",
  singup: baseUri + "/register",
  forgotPassword: baseUri + "/forgot-password",
  resetPassword: baseUri + "/reset-password",
  //getUserbyCode: baseUri + "/check-user",
  profileUpdate: baseUri + "/update-user",
  profileUpdateImage: baseUri + "/update-image",
  passwordUpdate: baseUri + "/update",
  getUserByToken: baseUri + "/check-login",
  loginWithSocialMedia: baseUri + "/login-with-social-media",
  logout: baseUri + "/logout",
  stores: baseUri + "/stores",
  coupons: baseUri + "/coupons",
  deeplink_generator: baseUri+ "/deeplink-generator",
  storesById: baseUri + '/stores-by-id',
  categories : baseUri + "/categories",
  statistics: baseUri + '/statistics',
  getAmount : baseUri + '/amounts',
  withdrawal_request : baseUri + '/withdrawal-request',
  getPages: baseUri + "/pages",
  contact: baseUri + "/contact",
  localisation : baseUri + "/localisation",
};
