/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../css/loading.css";
import Loading from "./Loading";
import Config from "../../appConfig";
import Swal from "../../constants/swal";

export default function Referrals(props) {
  const {
    localization,
    //direction,
    data,
    paginate,
    loading,
    more,
    search,
    searchValue,
    userData,
  } = props;

  return (
    <div className="card mb-4 ">
        <div className="table-search card-header bg-white inline ">
        <h6 className="m-0 active mb-2 mt-2">
          <button
            type="button"
            className="btn profile_btn"
            onClick={() => {
              Swal.fire(
                localization.referralLink,
                `<a href="${window.location.origin}/signup?ref=${userData.referral_code}">${window.location.origin}/signup?ref=${userData.referral_code}</a> <p>${localization.youCountRef} <span class="text-danger">${userData.users_ref}</span></p>`
              );
            }}>
            {localization.referralLink}
          </button>
        </h6>
        <div className="search_main">
          <div className="input-group">
            <input
              placeholder={localization.searchInput}
              defaultValue=""
              className="form-control"
              onChange={searchValue}
            />
            <div className="input-group-append">
              <button className="btn btn-link p-0" onClick={search}>
                <img src={`${Config.assetsUrl}/search.svg`} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body pr-0">
        <table
          className={`table beststors ${
            loading || data.length <= 0 ? "" : "table-striped"
          }`}>
          <thead className="head_tab">
            <tr>
              <th scope="col">{localization.user}</th>
              <th scope="col">{localization.amount_referral}</th>
              <th scope="col">{localization.status}</th>
              <th scope="col">{localization.date_created}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
                <td>
                  <Loading data={[0]} height={20} />
                </td>
              </tr>
            ) : (
              data.map((item, i) => {
                return (
                  <tr key={`row-${i}`} className={`static-row`}>
                    <td>{item.user.name}</td>
                    <td>{item.payment}</td>
                    <td>{localization.productStatus[item.status]}</td>
                    <td>{item.created_on}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className="MoreStors w-100 d-flex justify-content-center p-2 ">
          <button className="morestorebtn" onClick={paginate}>
            {localization.more}
            {(loading || more) && <div className="lds-dual-ring"></div>}
          </button>
        </div>
      </div>
    </div>
  );
}
