const fr = {
  app_name : "",
  locale: "Français",
  _locale: "fr",
  langAr: "Arabe",
  langFr: "Français",
  langEn: "Anglais",
  language: "Langue",
  home: "Accueil",
  logout: "Déconnecté",
  settings: "Paramètres",
  loginTitle: "Connectez-vous",
  signUpTitle: "Inscription",
  recoveryPass: "J'ai oublié le mot de passe?",
  recovery: "Récupération",
  register: "S'inscrire",
  registerQuestion: "Vous n'avez pas de compte? Inscrivez-vous ici",
  update: "Mise à jour",
  name: "Nom",
  first_name: "Nom",
  last_name: "Prénom",
  phone: "Numéro de téléphone",
  phone2: "Autre numéro de téléphone ",
  //'genre' : 'Le genre',
  genre: [
    "Le genre",
    {
      male: "Mâle",
      female: "Femelle",
    },
  ],
  email: "E-mail",
  password: "Mot de pass",
  confirmPassword: "Confirmer le mot de passe",
  date: "Date de naissance",
  newPassword: "Un nouveau mot de passe",
  oldPassword: "Votre mot de passe actuel",
  //"PassNotMatch" : "Mot de passe incorrect",
  dontChange:
    "Si vous ne souhaitez pas modifier votre mot de passe, laissez-le vide",
  btnSingUp: "S'inscrire",
  account: "Compte",
  newAccount: "Créer un nouveau compte",
  btnLogin: "Se connecter",
  connection: "Connexion",
  btnFacebook: "avec facebook",
  IhaveAccount: "J'ai un compte, revenir à la connexion",
  EmailNoValid: "E-mail déjà utilisé ou non valide",
  PhoneNoValid: "Numéro de téléphone déjà utilisé ou non valide",
  FieldEmpty: "Le champ du {key} est obligatoire.",
  FieldEmptyInput: "Touts les champs est obligatoire.",
  MinPassWord: "Le mot de passe doit contenir plus de 8 caractères et caractères spéciaux",
  PassNotMatch: "Veillez à faire correspondre les deux mot de passe",
  categories: "Catégories",
  bookmarks: "Favoris",
  about: "A propos",
  notification: "Notification",
  bookmarkMsg: "Ajouté aux favoris",
  bookmarkMsgAdded: "Ajouté précédemment",
  noInternet: "Erreur de connexion",
  resources: "Ressources",
  subscribe: "Rejoignez nous sur Facebook",
  contact: "Nous \n Contacter",
  contactUs: "Nous \n Contacter",
  developer: "Développeur par @DevUp",
  version: "Version",
  more: "Montre plus",
  notificationAlert:
    "Notification bloquée, accédez aux paramètres de votre système et activez les notifications",
  alert: "Alerte",
  social: "Des médias sociaux",
  share: "Partager",
  rate: "Noter l'application",
  rateText: "Noter l'application",
  privacy: "Politique de confidentialité",
  Comments: "Commentaires",
  users: "Utilisateur",
  recoveryPassBtn: "Récupération de mot de passe",
  recoveryPassTitle: "Entrez l'e-mail pour récupérer le mot de passe",
  recoveryPassMsg:
    "Un message de changement de mot de passe a été envoyé à votre e-mail. Parfois, il est envoyé dans une boîte spam",
  recoveryPassMsgError: "Il y a un problème! Vérifiez l'e-mail",
  profileUpdate: "Mise à jour de compte",
  profile: "Profile",
  youInfoUpdated: "Modifié avec succès",
  youInfoUpdatedUnsuccess: "Mise à jour infructueuse",
  total: "Montant total",
  apply: "Appliquer",
  delete: "supprimer",
  cancel: "Annuler",
  ok: "Ok",
  buy: "Passer la commande",
  deleteItme: "Voulez-vous vraiment supprimer?",
  open: "Ouvert",
  closed: "Fermé",
  selectAddress: "Choisissez l'adresse de livraison",
  address: "Address",
  //"activity" : "Activité",
  activity: [
    "Activité",
    {
      student: "Étudiant",
      employee: "Employé",
      particular: "Particulier",
    },
  ],
  Add: "Ajouter",
  MailNotSend: "Il y a un problème! L'email n'a pas été envoyé",
  yourMailIsReceived : "Merci. Nous avons reçu votre message et vous répondrons le plus rapidement possible",
  RegisterFiled:
    "Erreur L'enregistrement du nouveau compte n'a pas été terminé",
  YourInfoNotValid: "Les informations utilisateur ne sont pas valides",
  autre: "Autre",
  FieldCardEmpty: "Le champ du nom de la carte est obligatoire.",
  productNotFound: "produit non disponible ou unavailable",
  detail: "Détails du produit",
  producttTitle: "Caractéristiques du produit",
  searchInput: "Recherche",
  search: "Recherche",
  status: "Status",
  loginUnsuccessful: "Erreur lors de la connexion",
  active: "Active",
  disactive: "Disactive",
  accountStatus: "Statut du compte",
  maxFileSize: "Ne sont pas autorisés à télécharger plus d’une taille 2Mb",
  typeFile: "Assurez-vous que l’extension de fichier (png, gif, jpeg)",
  deleteIsSuccess: "Supprimer avec succès",
  deleteIsError: "Erreur lors de supprimer",
  addIsSuccess: "Ajouter  avec succès",
  date_created: "Date créée",
  date_updated: "Date modifiée",
  causeDelete: "Mentionnez la raison de la suppression",
  FieldCauseDeleteEmpty: "Le champ raison de la suppression est obligatoire.",
  num_ccp: "Numéro de ccp",
  coupon: "Coupon de réduction",
  coupons: "Coupons",
  allCoupons: "Tout les coupons",
  code: "Code",
  subTotal: "Sous-Total",
  shippingCost: "Frais de livraison",
  stores: "Magasins",
  store: "Magasin",
  help: "Aidez-moi",
  youWelcome: "Bienvenue",
  updatePassword: "Changement de mot de passe",
  referral: "Référence",
  referralLink: "Lien de référence",
  loginFacebook: "Se connecter avec Facebook",
  loginGoogle: "Se connecter avec google",
  error: "Erreur",
  youAccountCreated:
    "Compte créé avec succès. Un message d'activation a été envoyé à votre e-mail",
  youCountRef: "Numéro enregistré via votre lien de référence",
  /////////////////////////////////////////////////////
  msg_pub: "Magasinez et récupérez un pourcentage de vos achats",
  cashback: "Cashback",
  headLineTitle: "Cashback ? Recashit",
  serviceTitle1: "Inscrivez-vous sur le site",
  serviceTitle2: "Choisissez des offres ou des magasins",
  serviceTitle3: "Tirer",
  serviceDisc1:
    "Inscrivez-vous sur le site et choisissez votre magasin préféré et effectuez le cashback",
  serviceDisc2: "Attendez après le processus d'achat et le cashback sera déposé sur votre compte",
  serviceDisc3: "Il n'y a pas de montant minimum de retrait, retirez dès que le solde devient disponible",
  storesSupportIt: "Les magasins que nous soutenons",
  browseMore: "Parcourir la liste complète maintenant",
  aboutTitle: "À propos du cashback",
  aboutDisc: "Lorsque vous magasinez sur Internet, vous pouvez rencontrer de nombreuses offres, vous pouvez choisir le meilleur d'entre eux et le plus abordable et économiser? Mais avec les remises en argent au-dessus des économies que vous réalisez, vous bénéficiez d'un taux de retour de 30% pour votre achat dans la plupart des sites commerciaux internationaux pris en charge par votre pays. Inscrivez-vous maintenant, ne vous perdez pas.",
  storesPageTitle: "Parcourir, magasiner et obtenir des retours",
  shopNow: "Achetez maintenant",
  expiryDate: "Date d'expiration",
  upTo: "Jusqu'à",
  conditions: "Conditions!",
  conditionDescription:"",
  conditionDescriptionMore:"",
  average_approval_time: "Temps d'approbation moyen",
  days: "Jours",
  cash_withdrawal_request: "Demande de retrait d'argent",
  balance_cashback: [
    "Balance",
    {
      pending: "En attente",
      approved: "Approuvé",
      withdrawn: "Retiré",
    },
  ],
  cashback_percent: "pourcentage de cashback",
  product: "Produit",
  productStatus: {
    pending: "En attente",
    approved: "Approuvé",
    declined: "Diminué",
    approved_but_stalled: "Approuvé mais bloqué",
    "on hold": "En attente",
    confirmed: "Confirmé",
    "confirmed but delayed": "Confirmé mais retardé",
    withdrawn: "Retiré",
  },
  amount: "Le montant à retirer",
  amount_referral: "Le montant de la référence",
  methods: "Méthode de retrait",
  withdrawal_request_btn: "Envoyez la demande",
  withdrawal_request_on_hold: "Vous avez une demande de retrait en attente",
  min_withdrawal: "Le montant minimum de retrait est de 0.01 $",
  amount_not_available:
    "Vous avez soumis une demande de retrait d'un montant supérieur au montant disponible",
  your_request_has_been_received:
    "Votre demande a été reçue et vous serez notifié par e-mail",
  purchases: "Achats",
  itemLink : "Collez le lien du produit",
  checkProduct : "Gagnez plus en partageant votre lien privé",
  find_out_cashback : "Découvrez le lien",
  referrals : "Références",
  updateImage : "Modifier photo de profile",
  pages : {
    "terms-of-services" : "Conditions de service",
    "privacy-policy" : "Politique de confidentialité",
    "how-to-buy-with-a-cashback" : "Comment acheter avec cashback",
  },
  copyRight : 'Recashit.com Tous droits réservés',
  subject: 'Sujet',
  message : 'Message',
};

export default fr;
