/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import NavBar from "./Layers/NavBar";
import { useLocalization, useAuth } from "../context";
import { Link, useHistory } from "react-router-dom";
import { _validation } from "../helps/validation";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Codes from "../constants/codes";
import { BiLowVision, BiShowAlt } from "react-icons/bi";
import SocialMediaLogin from "./SocialMediaLogin";
import Config from '../appConfig';
import countryList from 'react-select-country-list'

export default function SignUpPage(props) {
  const { localization, setLang, direction } = useLocalization();
  const regions = localStorage.getItem("regions");

  const [dataInput, setDataInput] = React.useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
    phone: "",
    country: regions ?  regions : 'DZ',
  });
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const [refCode, setRefCode] = React.useState(undefined);

  const { authToken } = useAuth();
  const history = useHistory();

  const _countryList = countryList().getData();
  const _countryListValues = countryList().getValues().filter((country) => (country !== 'IL') && country);

  const getRef = async () => {
    const ref = await localStorage.getItem("ref");
    if (ref) {
      return ref;
    }
    return undefined;
  };

  //console.log(_countryListValues.includes(dataInput.country));

  React.useEffect(() => {
    let isMounted = true;
    setLang(props.match.params.lang);
    if (authToken && isMounted) {
      history.push("/profile");
    }
    getRef().then((ref) => {
      if (isMounted && ref) {
        setRefCode(ref);
      }
      return true;
    });

    return () => (isMounted = false);
  }, [authToken]);

  const getTextFromCodes = (code) => {
    if(!code){
      return "";
    }
    return localization[Codes[code].text];
  };

  const _onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setSuccess(false);
    setError(false);

    let result = validation();

    if(!_countryListValues.includes(dataInput.country)){
      result = false;
    }

    const data = { ...dataInput };

    data.fullname = data.first_name + " " + data.last_name;

    //data.identity = data.email;
    if (refCode) {
      data.referral_code = refCode;
    }

    data.register_type = "normal";
    
    //console.log(data);

    if (result) {
      postJson(Links.singup, data).then((response) => {
        if (response.status) {
          setSuccess(true);
        } else {
          setError(true);
        }
        setMessage(response?.results?.code);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const _setValues = (event) => {
    if (event.target.name === "confirmPassword") {
      if (event.target.value !== dataInput.password) {
        setError(true);
        setSuccess(false);
        setMessage(localization.PassNotMatch);
      } else {
        setError(false);
        setMessage("");
      }
    }
    setDataInput({
      ...dataInput,
      [event.target.name]: event.target.value,
    });
  };

  //console.log(dataInput);

  const validation = () => {
    for (const key in dataInput) {
      const results = _validation(key, dataInput[key], localization);
      if (!results.status) {
        if (results.validation) {
          setError(true);
          setMessage(results.validation);
        }
        return false;
      }
    }

    if (dataInput.confirmPassword !== dataInput.password) {
      setError(true);
      setMessage(localization.PassNotMatch);
      return false;
    }

    setError(false);
    setMessage("");
    return true;
  };

  return (
    <div className={`main_login ${direction}`}>
      <NavBar />

      <div className="login_section">
        <div className="login_box h_login_box">
          <div className="login_box_head" style={{backgroundImage : `url(${Config.assetsUrl}/backhead.png)`}}>
            <h3>{localization.signUpTitle}</h3>
          </div>

          <div className="login_box_form">
            {(error || success) && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
                role="alert">
                {Array.isArray(message)
                  ? message.map((item, index) => (
                      <p key={index}>- {getTextFromCodes(item)}</p>
                    ))
                  : message}
              </div>
            )}
            <form onSubmit={_onSubmit}>
              <input
                type="text"
                name="first_name"
                defaultValue={dataInput.first_name}
                placeholder={localization.first_name}
                onChange={_setValues}
              />

              <input
                type="text"
                name="last_name"
                defaultValue={dataInput.last_name}
                placeholder={localization.last_name}
                onChange={_setValues}
              />

              <input
                type="text"
                name="phone"
                defaultValue={dataInput.phone}
                placeholder={localization.phone}
                onChange={_setValues}
              />

              <input
                type="email"
                name="email"
                defaultValue={dataInput.email}
                placeholder={localization.email}
                onChange={_setValues}
              />

              <select name="country" onChange={_setValues} defaultValue={regions ? regions : "DZ"}>
                {
                  _countryList.map((country) => {
                    if(country.value !== 'IL'){
                      return <option key={country.value} value={country.value}>{country.label}</option>
                    }
                    return null;
                  })
                }
              </select>

              <div className="PasswordInput">
                <input
                  type={showPassword.password ? "text" : "password"}
                  name="password"
                  defaultValue={dataInput.password}
                  placeholder={localization.password}
                  onChange={_setValues}
                />
                <button
                  type="button"
                  onClick={() => {
                    setShowPassword({
                      ...showPassword,
                      password: !showPassword.password,
                    });
                  }}>
                  {showPassword.password ? (
                    <BiLowVision color="#91A2B7" size="1.3em" />
                  ) : (
                    <BiShowAlt color="#91A2B7" size="1.3em" />
                  )}
                </button>
              </div>

              <div className="PasswordInput">
                <input
                  type={showPassword.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  defaultValue={dataInput.confirmPassword}
                  placeholder={localization.confirmPassword}
                  onChange={_setValues}
                />
                <button
                  type="button"
                  onClick={() => {
                    setShowPassword({
                      ...showPassword,
                      confirmPassword: !showPassword.confirmPassword,
                    });
                  }}>
                  {showPassword.confirmPassword ? (
                    <BiLowVision color="#91A2B7" size="1.3em" />
                  ) : (
                    <BiShowAlt color="#91A2B7" size="1.3em" />
                  )}
                </button>
              </div>

              <button
                className="login_btn_box"
                disabled={loading}
                type="submit">
                {localization.signUpTitle}
                {loading && <div className="lds-dual-ring white"></div>}
              </button>
            </form>

            <SocialMediaLogin
              type="register"
              onResponse={(data) => {
                console.log(data);
                if (data.status === true) {
                  //console.log(_response.results);
                  setSuccess(true);
                  setMessage(data.codes);
                  //setAuthUserData(_response.results);
                } else {
                  setError(true);
                  setSuccess(false);
                  setMessage(data.codes);
                  //console.log(_response.results.code);
                }
              }}
            />
          </div>

          <div className="login_box_link">
            <label>
              <Link to="/login">{localization.IhaveAccount}</Link>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
