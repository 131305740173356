import React from 'react'
import { useLocalization } from '../../context';
import Config from '../../appConfig';

export default function About() {

  const { localization, direction } = useLocalization();

  return (
    <div className="header pt-5 pb-5" >
      <div className={`main_header_home ${direction}`} >
        <div className="header_l" >
          <img src={`${Config.assetsUrl}/about.png`} alt={localization.cashback} title={localization.cashback} />
        </div>
        <div className={`header_r ${direction}`} >
          <h3 className={direction}>{localization.aboutTitle}</h3>
          <p className={direction}>{localization.aboutDisc}</p>
        </div>
      </div>
    </div>
  )
}
