/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context";

function PrivateRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();
  const [loading, setLoading] = React.useState(true);
  
  React.useEffect(() => {
    let IsMounted = true;
    if(IsMounted){
      if(authToken){
        setLoading(false);
      }else{
        setLoading(false)
      }
    }
    return () => IsMounted = false;
  },[])

  if(loading){
    return null;
  }
  else {
    return (
      <Route
        {...rest}
        render={props =>
          authToken ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

export default PrivateRoute;